import { GetterTree } from "vuex";
import * as types from "@/store/constants/user_types.ts";
const Getters: GetterTree<any, any> = {
    [types.GETTER_USERNAME](state) {
        return state.username;
    },
    [types.GETTER_TOKEN](state) {
        return state.token;
    },
    [types.GETTER_TOKEN_RECORD_TIMESTAMP](state) {
      return state.token_record_timestamp;
    },
    [types.GETTER_LEVEL](state) {
        return state.roleLevel;
    },
    [types.GETTER_PRIVATE_URL](state) {
        return state.privateUrl;
    },
    [types.GETTER_CURRENT_USER_ID](state) {
        return state.userId;
    },
    [types.GETTER_APP_LIST](state) {
        return state.appList;
    },
    [types.GETTER_USER_LIST](state) {
        return state.userList;
    },
    [types.GETTER_CURRENT_APP_ID](state) {
        return state.currentChooseAppId
    },
    [types.GETTER_CATEGORY_LIST](state) {
        return state.categoryList;
    },
    [types.GETTER_ACTUAL_CATEGORY_LIST](state) {
        return state.categoryList.filter((v: any) => v.categoryName !== '未分类')
    },
    [types.GETTER_CURRENT_APP](state) {
        const appList = state.appList;
        const appId = state.currentChooseAppId;
        return appList.find((v: any) => v.appId === appId);
    },
    [types.GETTER_VERSION_TOKEN](state) {
        return state.versionToken;
    },
    [types.GETTER_USER_CACHE](state) {
        return state.cacheUserHistory;
    }
}
export default Getters;
