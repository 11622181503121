import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "display: none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_upload_outlined = _resolveComponent("upload-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    width: 400,
    title: "编辑信息",
    placement: "right",
    visible: _ctx.visible,
    "onUpdate:visible": _ctx.onClose
  }, {
    extra: _withCtx(() => [
      _createVNode(_component_a_button, {
        style: {"margin-right":"8px"},
        onClick: _ctx.onClose
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("取消")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.onSave,
        loading: _ctx.submitLoading
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("提交")
        ])),
        _: 1
      }, 8, ["onClick", "loading"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_title, {
          level: 5,
          style: {"font-size":"14px"}
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("视频/截图")
          ])),
          _: 1
        }),
        _createVNode(_component_a_upload, {
          multiple: true,
          "file-list": _ctx.fileList,
          "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
          "before-upload": _ctx.beforeUpload,
          headers: _ctx.uploadHeader,
          action: _ctx.iconUploadUrl,
          onChange: _ctx.handleChange,
          "list-type": "picture",
          data: _ctx.supplyData,
          onPreview: _ctx.onPreview
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, null, {
              default: _withCtx(() => [
                _createVNode(_component_upload_outlined),
                _cache[7] || (_cache[7] = _createTextVNode(" 上传 "))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["file-list", "before-upload", "headers", "action", "onChange", "data", "onPreview"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_title, {
          level: 5,
          style: {"font-size":"14px","margin-top":"12px"}
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("备注")
          ])),
          _: 1
        }),
        _createVNode(_component_a_textarea, {
          value: _ctx.description,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
          placeholder: "请输入备注",
          autoSize: { minRows: 3, maxRows: 10 }
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_title, {
          level: 5,
          style: {"font-size":"14px","margin-top":"12px"}
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("关联的包版本")
          ])),
          _: 1
        }),
        _createVNode(_component_a_select, {
          placeholder: "请选择包版本",
          style: {"width":"100%"},
          value: _ctx.current_app_version_id,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.current_app_version_id) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionList, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                value: item.id,
                key: item.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" build " + _toDisplayString(item.incId), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_image_preview_group, {
          preview: { visible: _ctx.imgPreviewVisible, current: _ctx.previewIndex, onVisibleChange: vis => (_ctx.imgPreviewVisible = vis) }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgFiles, (i, img_i) => {
              return (_openBlock(), _createBlock(_component_a_image, {
                key: img_i,
                src: i
              }, null, 8, ["src"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["preview"])
      ]),
      _createVNode(_component_vue_easy_lightbox, {
        index: _ctx.previewIndex,
        visible: _ctx.videoPreviewVisible,
        imgs: _ctx.videoFiles,
        onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.videoPreviewVisible = false))
      }, null, 8, ["index", "visible", "imgs"])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}