import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icon.png'


const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "form-login" }
const _hoisted_4 = { class: "login-main" }
const _hoisted_5 = { class: "bottom-footer" }
const _hoisted_6 = { class: "footer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrcodeOutlined = _resolveComponent("QrcodeOutlined")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_UserSwitchOutlined = _resolveComponent("UserSwitchOutlined")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_phone_outlined = _resolveComponent("phone-outlined")!
  const _component_lock_outlined = _resolveComponent("lock-outlined")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_SafetyOutlined = _resolveComponent("SafetyOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_group = _resolveComponent("a-input-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "login-top" }, [
          _createElementVNode("div", { class: "login-header" }, [
            _createElementVNode("img", { src: _imports_0 }),
            _createElementVNode("span", null, "PGY")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_spin, { spinning: _ctx.spinning }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tabs, {
                activeKey: _ctx.activeKey,
                "onUpdate:activeKey": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeKey) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tab_pane, { key: "qrcode" }, {
                    tab: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_QrcodeOutlined),
                        _cache[6] || (_cache[6] = _createTextVNode("扫码登录 "))
                      ])
                    ]),
                    default: _withCtx(() => [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { id: "login_container" }, null, -1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_tab_pane, { key: "user" }, {
                    tab: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_UserSwitchOutlined),
                        _cache[8] || (_cache[8] = _createTextVNode(" 账户登录 "))
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_form, {
                        model: _ctx.formState,
                        name: "basic",
                        "wrapper-col": { span: 24 },
                        autocomplete: "off",
                        onFinish: _ctx.onFinish,
                        onFinishFailed: _ctx.onFinishFailed,
                        style: {"padding-bottom":"5px"}
                      }, {
                        default: _withCtx(() => [
                          (_ctx.resetState)
                            ? (_openBlock(), _createBlock(_component_a_typography_title, {
                                key: 0,
                                class: "header-title",
                                level: 5
                              }, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode(" 找回密码 ")
                                ])),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_typography_title, {
                                key: 1,
                                class: "header-title",
                                level: 5
                              }, {
                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                  _createTextVNode(" 用户登录 ")
                                ])),
                                _: 1
                              })),
                          (!_ctx.resetState)
                            ? (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 2,
                                name: "username",
                                rules: [{ required: true, message: 'Please input your username!' }]
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    autocomplete: "new-password",
                                    value: _ctx.formState.username,
                                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.username) = $event)),
                                    placeholder: "用户名",
                                    size: "large"
                                  }, {
                                    prefix: _withCtx(() => [
                                      _createVNode(_component_user_outlined, { type: "user" })
                                    ]),
                                    suffix: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createElementVNode("div", { style: {"width":"16px"} }, null, -1)
                                    ])),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 3,
                                name: "phoneNumber",
                                rules: [{ required: true, message: 'Please input your phoneNumber' }]
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    autocomplete: "new-password",
                                    value: _ctx.formState.phoneNumber,
                                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.phoneNumber) = $event)),
                                    placeholder: "手机号",
                                    size: "large"
                                  }, {
                                    prefix: _withCtx(() => [
                                      _createVNode(_component_phone_outlined)
                                    ]),
                                    suffix: _withCtx(() => _cache[12] || (_cache[12] = [
                                      _createElementVNode("div", { style: {"width":"16px"} }, null, -1)
                                    ])),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_a_form_item, {
                            name: "password",
                            rules: [{ required: true, message: 'Please input your password!' }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                autocomplete: "new-password",
                                placeholder: _ctx.resetState ? '新密码' : '密码',
                                size: "large",
                                value: _ctx.formState.password,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.password) = $event))
                              }, {
                                prefix: _withCtx(() => [
                                  _createVNode(_component_lock_outlined)
                                ]),
                                _: 1
                              }, 8, ["placeholder", "value"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_form_item, {
                            name: "phoneCode",
                            class: "verify-box",
                            rules: [{ required: true, message: 'Please input your verify code!' }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_group, { compact: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    size: "large",
                                    value: _ctx.formState.phoneCode,
                                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.phoneCode) = $event)),
                                    style: {"width":"calc(100% - 112px)"},
                                    type: "tel",
                                    maxlength: 6,
                                    autocomplete: "one-time-code",
                                    placeholder: "请输入验证码",
                                    ref: "verify_input"
                                  }, {
                                    prefix: _withCtx(() => [
                                      _createVNode(_component_SafetyOutlined)
                                    ]),
                                    _: 1
                                  }, 8, ["value"]),
                                  _createVNode(_component_a_button, {
                                    style: {"width":"112px"},
                                    size: "large",
                                    type: "primary",
                                    onClick: _ctx.getCode,
                                    disabled: _ctx.countdown > 0
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.countdown ? _ctx.countdown : "获取验证码"), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick", "disabled"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (!_ctx.resetState)
                            ? (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 4,
                                style: {"margin":"-17px 0 5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goReset())),
                                    type: "link",
                                    style: {"padding-left":"0"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.resetState ? '返回登录' : '找回密码？'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 5,
                                style: {"margin":"-17px 0 5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "link",
                                    style: {"padding-left":"0"},
                                    onClick: _ctx.returnLogin
                                  }, {
                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                      _createTextVNode("返回登录")
                                    ])),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_a_form_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                loading: _ctx.btnLoading,
                                style: {"width":"100%"},
                                size: "large",
                                type: "primary",
                                "html-type": "submit"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.resetState ? '验证' : '登录'), 1)
                                ]),
                                _: 1
                              }, 8, ["loading"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "onFinish", "onFinishFailed"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["activeKey"])
            ]),
            _: 1
          }, 8, ["spinning"])
        ])
      ])
    ]),
    _createElementVNode("footer", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, " Copyright © " + _toDisplayString(new Date().getFullYear()) + " Inc. All Rights Reserved ", 1)
    ])
  ]))
}