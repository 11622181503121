export const APPS = "api/apps";
export const APP = "api/app";
export const APP_VERSION = "api/app_versions";
export const APP_VERSION_DETAIL = "api/app_version";
export const UPLOAD_PACKAGE = "api/upload_app";
export const LOGIN = "api/user/login";
export const LOGOUT = "api/user/logout";
export const PHONE_CODE = "api/send_phone_code";
export const USER = "api/user";
export const USER_LIST = "api/users";
export const DISTRIBUTE_ROLE = "api/user_role";
export const ROLE_LIST = "api/roles";
export const ROLE = "api/role";
export const FORGOT = "api/forget_password";
export const BIND_APP = "api/user/bind_app";
export const APP_USER_RELATION = "api/app_users";
export const CATEGORY_PATH = "api/category";
export const CATEGORY_LIST = "api/categorys";
export const UDID_LIST = "api/udids";
export const UDID = "api/udid";
export const UDID_EXPORT = "api/udid/export";
export const QRCODE_PATH = "api/qywx_login";
export const UDID_IMPORT = "api/udid/import";
export const COLLECT = "api/app_collect";
export const CANCEL_COLLECT = "api/app_cancel_collect";
export const NOTIFICATIONS = "api/messages";
export const NOTIFICATION = "/api/message";
export const SIGNED_URL = "/api/get_signed_url";
export const V2_UPLOAD_APP = "/api/v2/upload_app";
export const CHECK_TOKEN = "/api/token_check";
export const SEND_WECOM_MESSAGE = "/api/send_wecom_message";
export const UPLOAD_CERTIFICATION = "/api/cert_upload";
export const CERTIFICATION_LIST = "/api/cert_list";
export const RESIGN = "/api/zsign";
export const MULTI_SIGNED_URL_UPLOAD = "/api/multi_signed_url_upload";
export const LARK_LOGIN = "/api/user/lark_qr_login";
export const SEND_LARK_MESSAGE = "/api/send_lark_message";
export const SEND_MULTI_LARK_MESSAGE = "/api/workflow/send_multi_commits";
export const APPLE_ACCOUNT_LIST = "/api/apple_accounts";
export const APPLE_ACCOUNT = "/api/apple_account";
export const APPLE_ACCOUNT_UPLOAD = "/api/apple_account/update";
export const APPLE_ACCOUNT_BIND_UDID = "/api/apple_account/bind";

export const workflow = "/api/workflow"; // 工作流列表 / 新增工作流 / 删除工作流 公用
export const workflowUpdate = "/api/workflow/update";
export const workflowUpdateOrder = "/api/workflow/update_order";
export const commitLogList = "/api/commit_log_list";
export const updateCommit = "/api/commit_log/update";

export const simple_version_list = '/api/app_versions/simple'
export const bind_commit = '/api/app_version/bind_commit'
export const commit_detail = '/api/commit_log/detail'
export const commit_username_list = '/api/username_list'
