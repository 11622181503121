import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_NotificationOutlined = _resolveComponent("NotificationOutlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_Notification_modal = _resolveComponent("Notification-modal")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_logout_outlined = _resolveComponent("logout-outlined")!
  const _component_a_dropdown_button = _resolveComponent("a-dropdown-button")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: "home",
    onClick: _ctx.closeInnerModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_header, { style: {
      position: 'fixed',
      zIndex: 10,
      width: '100%',
      padding: 0,
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 2px 4px rgba(33, 33, 33, .2)'
    } }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("a", { class: "title" }, "发布系统", -1)),
          _createVNode(_component_a_menu, {
            selectedKeys: _ctx.currentTab,
            "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            mode: "horizontal",
            style: { lineHeight: '64px', flex: 1 }
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      class: _normalizeClass(["menu-link", {'m-0': _ctx.isMobile}]),
                      to: item.href
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "to"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["selectedKeys"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isValidPage)
              ? (_openBlock(), _createBlock(_component_a_badge, {
                  key: 0,
                  count: _ctx.notificationList.length,
                  style: {"cursor":"pointer"},
                  onClick: _withModifiers(_ctx.toggleModalState, ["stop","prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NotificationOutlined, { style: {"font-size":"19px","color":"#82929a","cursor":"pointer"} })
                  ]),
                  _: 1
                }, 8, ["count", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Notification_modal, {
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
              visible: _ctx.modalShow,
              rightWidth: _ctx.rightWidth,
              onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modalShow=false))
            }, null, 8, ["visible", "rightWidth"])
          ]),
          _createVNode(_component_a_dropdown_button, {
            style: {"margin-right":"30px","margin-left":"20px"},
            ref: "dropdown"
          }, {
            overlay: _withCtx(() => [
              _createVNode(_component_a_menu, { onClick: _ctx.handleMenuClick }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_logout_outlined),
                      _cache[3] || (_cache[3] = _createTextVNode(" logout "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_avatar, {
                size: 20,
                style: {"font-size":"14px","margin-right":"5px"}
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_UserOutlined)
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.username) + " ", 1)
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, { onClick: _ctx.closeInnerModal }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}