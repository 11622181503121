
import { defineComponent, PropType } from 'vue'
import SvgIcon from './svg-icon.vue'
import { prefixCls } from './constant'

export default defineComponent({
  name: 'Toolbar',
  components: {
    SvgIcon
  },
  props: {
    zoomIn: {
      type: Function as PropType<() => void>,
      default: () => {
        //
      }
    },
    zoomOut: {
      type: Function as PropType<() => void>,
      default: () => {
        //
      }
    },
    rotateLeft: {
      type: Function as PropType<() => void>,
      default: () => {
        //
      }
    },
    rotateRight: {
      type: Function as PropType<() => void>,
      default: () => {
        //
      }
    },
    resize: {
      type: Function as PropType<() => void>,
      default: () => {
        //
      }
    }
  },
  setup() {
    return { prefixCls }
  }
})
