
import { defineComponent } from 'vue'
import { prefixCls } from './constant' // 建议从常量文件导入

export default defineComponent({
  name: 'SvgIcon',
  props: {
    type: {
      type: String,
      required: true  // 根据需求调整是否必需
    }
  },
  setup() {
    return {
      prefixCls  // 如果从 constant 导入，这里直接返回
    }
  }
})
