
import { Options, Vue } from 'vue-class-component';
import 'video.js/dist/video-js.css';
import { Tabs } from "ant-design-vue";
import Sortable from "sortablejs";

@Options({
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane
  }
})
export default class HelloWorld extends Vue {
  workflowList = [{
    id: 1,
    tabName: 'test1'
  }, {
    id: 2,
    tabName: 'test2'
  }, {
    id: 3,
    tabName: 'test3'
  }, {
    id: 4,
    tabName: 'test4'
  }]

  private sortable: any;
  private pressTimer: ReturnType<typeof setTimeout> | null = null;
  private currentElement: HTMLElement | null = null;

  mounted() {
    this.$nextTick(() => {
      const el = document.querySelector('#draggable-tabs .ant-tabs-nav-list');
      this.sortable = Sortable.create(el, {
        delay: 500,
        filter: '.ant-tabs-tab:first-child',
        draggable: '.ant-tabs-tab:not(:first-child)',
        animation: 150,
        onStart: () => {
          if (this.currentElement) {
            this.currentElement.style.cursor = 'move';
          }
        },
        onEnd: (evt: any) => {
          const { oldIndex, newIndex } = evt;
          const newList = [...this.workflowList];
          const [removed] = newList.splice(oldIndex - 1, 1);
          newList.splice(newIndex - 1, 0, removed);
          this.workflowList = newList;

          if (this.currentElement) {
            this.currentElement.style.cursor = '';
          }
        }
      });
    })
  }

  handleMouseDown(event: MouseEvent, workflow: any) {
    if (!workflow) return;

    this.currentElement = event.currentTarget as HTMLElement;
    this.pressTimer = setTimeout(() => {
      if (this.currentElement) {
        this.currentElement.style.cursor = 'move';
      }
    }, 500);
  }

  handleMouseUp() {
    if (this.pressTimer) {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    }
    if (this.currentElement) {
      this.currentElement.style.cursor = '';
    }
  }

  handleMouseLeave() {
    this.handleMouseUp();
  }
}
