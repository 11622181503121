
import {
  UserOutlined,
  LockOutlined,
  PhoneOutlined,
  UserSwitchOutlined,
  QrcodeOutlined,
  SafetyOutlined,
} from '@ant-design/icons-vue';
import {sendCode, forgotPassword, larkLogin} from "@/api/service/login";
import { Action } from "vuex-class";
import * as types from "@/store/constants/user_types";
import {Options, Vue, Watch} from "vue-property-decorator";
import {Button, Form, Input, Typography, Tabs, Spin} from "ant-design-vue";
import Toast from "@/utils/toast";
import {isString} from "@/utils/utils";
import {larkAutoLogin} from "@/services/launch/initRouter";
Vue.registerHooks(["beforeRouteEnter"])
@Options({
  components: {
    UserOutlined,
    LockOutlined,
    UserSwitchOutlined,
    QrcodeOutlined,
    PhoneOutlined,
    SafetyOutlined,
    ATypographyTitle: Typography['Title'],
    AForm: Form,
    AFormItem: Form['Item'],
    AButton: Button,
    AInputGroup: Input['Group'],
    AInput: Input,
    AInputPassword: Input['Password'],
    ATabs: Tabs, // 引入 Tabs 组件
    ATabPane: Tabs.TabPane,
    ASpin: Spin
  }
})
export default class Browser extends Vue {
  @Action(types.LOGIN)
  private login: any;
  @Action(types.HANDLE_LOGIN_SUCCESS)
  private handleLoginSuccess: any;

  @Watch("$route", { immediate: true })
  private async WatchRouteParams(val: any) {
    // 扫码登录之后的逻辑
    if (val && val.query && val.query.code) {
      this.sendCodeToService(val.query.code, val.query.redirectFrom);
    }
  }

  private formState: any = {};
  private countdown = 0;
  private interval: any;
  private redirectedFrom: any;
  private resetState = false;
  private btnLoading = false;
  private activeKey = "qrcode";
  private spinning = false;

  get hasCodeInRoute() {
    return this.$route.query && this.$route.query.code;
  }
  get autoLogin() {
    return this.$route.query && this.$route.query.auto_login;
  }

  private async onFinish() {
    try {
      const {username, password, phoneCode, phoneNumber} = this.formState
      // 区分是重置密码还是登录
      if (this.resetState) {
        await forgotPassword(password, phoneCode, phoneNumber);
        Toast.show("success", "重置成功,请登录");
        setTimeout(() => {
          this.returnLogin();
        }, 1000);
      } else {
        this.btnLoading = true;
        await this.login({
          username,
          phoneCode,
          password,
          redirectedFrom: this.redirectedFrom
        });
        this.btnLoading = false;
      }
    } catch (e) {
      Toast.show("error", e);
      this.btnLoading = false;
    }
  }

  private async getCode() {
    try {
      if (!this.resetState && !this.formState.username) {
        Toast.show("warning", "请输入用户名");
        return;
      }
      if (this.resetState && !this.formState.phoneNumber) {
        Toast.show("warning", "请输入手机号");
        return;
      }
      this.countdown = 60;
      this.interval = setInterval(() => {
        this.countdown--;
        if (this.countdown < 1) {
          clearInterval(this.interval);
        }
      }, 1000);
      await sendCode(this.resetState ? {
        phone: this.formState.phoneNumber
      } : {
        username: this.formState.username
      });
    } catch (e) {
      if (e && e.code === 304) {
        Toast.show("warning", "验证码未过期，已自动填充");
        setTimeout(() => {
          this.formState.phoneCode = e.msg;
        }, 1000)
      } else {
        Toast.show("error", e);
      }
    }
    // (this.$refs.verify_input as any).focus();
  }
  private onFinishFailed() {
    //
  }

  private validator(password: string) {
    if (password == null || password.length < 6 || password.length > 10) {
      return false;
    }
    const reg_sp = new RegExp(/^(\d)\1{5}$/);
    const str = "0123456789_9876543210";
    if (reg_sp.test(password) || str.indexOf(password)) {
      return false;
    }
    return true;
  }

  async beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      // 记录登录的路由从哪里过来，403的page无需记录
      vm.redirectedFrom = (from.name && from.name !== "403") ? from.fullPath : "";
    });
  }

  goReset() {
    if (this.interval) {
      clearInterval(this.interval);
      this.countdown = 0;
    }
    this.resetState = true;
    this.formState.password = "";
    this.formState.phoneCode = "";
  }

  returnLogin() {
    this.resetState = false;
    this.formState.phoneCode = "";
    if (this.interval) {
      clearInterval(this.interval);
      this.countdown = 0;
    }
  }

  initQrcode() {
    // 避免重复渲染
    const redirect_uri = `${window.location.origin}/login?redirectFrom=${this.redirectedFrom}`;
    const gotoUrl = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${process.env.VUE_APP_LARK_ID}&redirect_uri=${redirect_uri}&response_type=code&state=success_login`;
    const QRLoginObj = window.QRLogin({
      id: 'login_container',
      goto: `${gotoUrl}`,
      width: "100%",
      height: "320",
      style:
          'background-color: rgb(244, 246, 248);',
    });
    const handleMessage = (event: any) => {
      const { origin } = event;
      console.log("handleMessage", QRLoginObj.matchOrigin(origin));
      if (
          QRLoginObj.matchOrigin(origin) &&
          window.location.href.indexOf("/login") > -1
      ) {
        const loginTmpCode = event.data;
        // 避免配置不对无法登录
        if (loginTmpCode && !isString(loginTmpCode)) {
          console.log("loginTmpCode", loginTmpCode);
          window.location.href = `${gotoUrl}&tmp_code=${loginTmpCode}`;
        }
      }
    };
    if (typeof window.addEventListener !== 'undefined') {
      window.addEventListener('message', handleMessage, false);
    } else if (typeof window.attachEvent !== 'undefined') {
      window.attachEvent('onmessage', handleMessage);
    }
  }

  async sendCodeToService(code: any, queryRedirectedFrom?: string) {
    try {
      const result = await larkLogin(code);
      this.handleLoginSuccess({
        ...result,
        ...(queryRedirectedFrom ? { redirectedFrom: queryRedirectedFrom } : {})
      });
      this.spinning = false;
    } catch (e) {
      this.initQrcode();
    }
  }

  created() {
    // 监听 visibilitychange 事件
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    this.spinning = true;
  }

  async onVisibilityChange() {
    if (document.hidden) {
      // 页面进入后台
      console.log('应用进入后台');
    } else {
      try {
        // 页面进入前台
        if (window.h5sdk) {
          this.spinning = true;
          await larkAutoLogin(this.redirectedFrom);
          this.spinning = false;
        }
      } catch (e) {
        //
      }
    }
  }
  async mounted() {
    if ((!this.hasCodeInRoute && !this.autoLogin) || !window.h5sdk) {
      this.initQrcode();
      this.spinning = false;
    } else if (this.autoLogin) {
      try {
        await larkAutoLogin(this.redirectedFrom);
        this.spinning = false;
      } catch (e) {
        this.initQrcode();
        this.spinning = false;
      }
    }
  }
  beforeUnmount() {
    // 移除 visibilitychange 事件监听器
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }
}
