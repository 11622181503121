import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_loading = _resolveComponent("img-loading")!
  const _component_img_on_error = _resolveComponent("img-on-error")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_img_title = _resolveComponent("img-title")!
  const _component_toolbar = _resolveComponent("toolbar")!

  return (_openBlock(), _createBlock(_Transition, {
    name: `${_ctx.prefixCls}-fade`
  }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "modal",
            class: _normalizeClass([`${_ctx.prefixCls}-img-modal`, `${_ctx.prefixCls}-modal`]),
            onClick: _cache[16] || (_cache[16] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMaskClick && _ctx.onMaskClick(...args)), ["self"])),
            onWheel: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.onWheel && _ctx.onWheel(...args)))
          }, [
            _createVNode(_Transition, {
              name: `${_ctx.prefixCls}-fade`,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (_ctx.loading)
                    ? _renderSlot(_ctx.$slots, "loading", { key: 0 }, () => [
                        _createVNode(_component_img_loading)
                      ], true)
                    : _createCommentVNode("", true),
                  (_ctx.loadError)
                    ? _renderSlot(_ctx.$slots, "onerror", { key: 1 }, () => [
                        _createVNode(_component_img_on_error)
                      ], true)
                    : (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(`${_ctx.prefixCls}-img-wrapper`),
                        style: _normalizeStyle(_ctx.imgWrapperStyle)
                      }, [
                        (_ctx.isImage)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              ref: "realImg",
                              class: _normalizeClass(`${_ctx.prefixCls}-img`),
                              src: _ctx.visibleMediaSrc,
                              draggable: "false",
                              onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMouseDown($event))),
                              onMouseup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMouseUp($event))),
                              onMousemove: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleMouseMove($event))),
                              onTouchstart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleTouchStart($event))),
                              onTouchmove: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleTouchMove($event))),
                              onTouchend: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleTouchEnd($event))),
                              onLoad: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleRealImgLoad && _ctx.handleRealImgLoad(...args))),
                              onDragstart: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleDragStart($event))),
                              onDblclick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleDblClick($event)))
                            }, null, 42, _hoisted_1))
                          : (_openBlock(), _createElementBlock("video", {
                              key: 1,
                              ref: "mediaElement",
                              class: _normalizeClass(`${_ctx.prefixCls}-video`),
                              src: _ctx.visibleMediaSrc,
                              controls: "",
                              onLoadeddata: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleRealMediaLoad && _ctx.handleRealMediaLoad(...args))),
                              onError: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleMediaError && _ctx.handleMediaError(...args)))
                            }, null, 42, _hoisted_2))
                      ], 6))
                ])
              ]),
              _: 3
            }, 8, ["name"]),
            (_ctx.isImage)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  style: {"display":"none"},
                  src: _ctx.visibleMediaSrc,
                  onError: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleImgError && _ctx.handleImgError(...args))),
                  onLoad: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleTestImgLoad && _ctx.handleTestImgLoad(...args)))
                }, null, 40, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(`${_ctx.prefixCls}-btns-wrapper`)
            }, [
              _renderSlot(_ctx.$slots, "prev-btn", { prev: _ctx.onPrevClick }, () => [
                (_ctx.imgList.length > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["btn__prev", { disable: !_ctx.loop && _ctx.imgIndex <= 0 }]),
                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onPrevClick && _ctx.onPrevClick(...args)))
                    }, [
                      _createVNode(_component_svg_icon, { type: "prev" })
                    ], 2))
                  : _createCommentVNode("", true)
              ], true),
              _renderSlot(_ctx.$slots, "next-btn", { next: _ctx.onNextClick }, () => [
                (_ctx.imgList.length > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["btn__next", { disable: !_ctx.loop && _ctx.imgIndex >= _ctx.imgList.length - 1 }]),
                      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.onNextClick && _ctx.onNextClick(...args)))
                    }, [
                      _createVNode(_component_svg_icon, { type: "next" })
                    ], 2))
                  : _createCommentVNode("", true)
              ], true),
              _renderSlot(_ctx.$slots, "close-btn", { close: _ctx.closeDialog }, () => [
                _createElementVNode("div", {
                  class: "btn__close",
                  onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
                }, [
                  _createVNode(_component_svg_icon, { type: "close" })
                ])
              ], true),
              (_ctx.imgTitle && !_ctx.titleDisabled && !_ctx.loading && !_ctx.loadError)
                ? _renderSlot(_ctx.$slots, "title", { key: 0 }, () => [
                    _createVNode(_component_img_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.imgTitle), 1)
                      ]),
                      _: 1
                    })
                  ], true)
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "toolbar", {
                toolbarMethods: {
            zoomIn: _ctx.zoomIn,
            zoomOut: _ctx.zoomOut,
            rotate: _ctx.rotateLeft,
            rotateLeft: _ctx.rotateLeft,
            rotateRight: _ctx.rotateRight,
            resize: _ctx.resize
          }
              }, () => [
                _createVNode(_component_toolbar, {
                  prefixCls: _ctx.prefixCls,
                  zoomIn: _ctx.zoomIn,
                  zoomOut: _ctx.zoomOut,
                  rotateLeft: _ctx.rotateLeft,
                  rotateRight: _ctx.rotateRight,
                  resize: _ctx.resize
                }, null, 8, ["prefixCls", "zoomIn", "zoomOut", "rotateLeft", "rotateRight", "resize"])
              ], true)
            ], 2)
          ], 34))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name"]))
}