import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "notification-dropdown-header" }
const _hoisted_2 = {
  class: "pull-left",
  style: {"padding-left":"5px","font-size":"14px"}
}
const _hoisted_3 = { style: {"font-weight":"500"} }
const _hoisted_4 = { class: "notice-count" }
const _hoisted_5 = { class: "notification-list" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { class: "time" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!

  return (_ctx.modalVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "dropdown-menus",
        style: _normalizeStyle(_ctx.activeStyle)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[2] || (_cache[2] = _createTextVNode("你有 ")),
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.notificationList.length), 1),
              _cache[1] || (_cache[1] = _createTextVNode(" 条 "))
            ]),
            _cache[3] || (_cache[3] = _createTextVNode(" 未读通知"))
          ]),
          (_ctx.notificationList && _ctx.notificationList.length > 0)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "link",
                onClick: _ctx.readAll,
                ghosts: "",
                size: "small"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" 一键已读 ")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "notification-box",
              key: item.id
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(JSON.parse(item.messageInfo).username + "更新了"), 1),
                  _createElementVNode("a", {
                    class: "link-btn",
                    onClick: ($event: any) => (_ctx.linkApp(JSON.parse(item.messageInfo).appId))
                  }, _toDisplayString(JSON.parse(item.messageInfo).appName), 9, _hoisted_7)
                ]),
                _createElementVNode("div", _hoisted_8, _toDisplayString(JSON.parse(item.messageInfo).description), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_tooltip, {
                    title: _ctx.getCurrentTime(item.createTime)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentSplitTime(item.createTime)), 1)
                    ]),
                    _: 2
                  }, 1032, ["title"])
                ])
              ]),
              _createVNode(_component_a_tooltip, { title: "点击忽略此条通知" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "ignore",
                    onClick: ($event: any) => (_ctx._ignore(item.id, JSON.parse(item.messageInfo).appId))
                  }, [
                    _createVNode(_component_DeleteOutlined, { style: {"font-size":"16px","color":"#82929b"} })
                  ], 8, _hoisted_10)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goMessage && _ctx.goMessage(...args))),
          class: "notification-link"
        }, "查看所有通知")
      ], 4))
    : _createCommentVNode("", true)
}