import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/order.png'
import _imports_1 from '@/assets/all_icon.png'
import _imports_2 from '@/assets/back.png'


const _hoisted_1 = {
  key: 0,
  class: "item"
}
const _hoisted_2 = {
  key: 1,
  class: "item"
}
const _hoisted_3 = { class: "menu-title searchform" }
const _hoisted_4 = {
  key: 0,
  class: "search-top-hr"
}
const _hoisted_5 = {
  key: 0,
  class: "mark"
}
const _hoisted_6 = {
  key: 0,
  class: "mark"
}
const _hoisted_7 = {
  key: 0,
  class: "mark"
}
const _hoisted_8 = {
  key: 2,
  class: "filter-container order"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "mark"
}
const _hoisted_11 = {
  key: 3,
  class: "filter-container"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "mark"
}
const _hoisted_14 = { class: "count" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "classify" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 0,
  class: "mark"
}
const _hoisted_19 = { class: "count" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "classify" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = {
  key: 0,
  class: "mark"
}
const _hoisted_24 = { class: "count" }
const _hoisted_25 = {
  class: "app-list",
  ref: "appList"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "app-info" }
const _hoisted_28 = { class: "app-type" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 0,
  class: "mark"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 0,
  class: "mark"
}
const _hoisted_33 = { class: "count" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "classify" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = {
  key: 0,
  class: "mark"
}
const _hoisted_38 = { class: "count" }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "classify" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = {
  key: 0,
  class: "mark"
}
const _hoisted_43 = { class: "count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuUnfoldOutlined = _resolveComponent("MenuUnfoldOutlined")!
  const _component_MenuFoldOutlined = _resolveComponent("MenuFoldOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_AppstoreOutlined = _resolveComponent("AppstoreOutlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_SwapLeftOutlined = _resolveComponent("SwapLeftOutlined")!
  const _component_SwapRightOutlined = _resolveComponent("SwapRightOutlined")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_app_modal = _resolveComponent("app-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    "has-sider": "",
    class: "content-container",
    onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => {_ctx.btn_collpase=false;_ctx.filter_show=false;_ctx.order_show=false}, ["stop","prevent"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        class: _normalizeClass(["sider", {'o-h': !_ctx.stateCollapsed}]),
        width: _ctx.siderWidth
      }, {
        default: _withCtx(() => [
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                class: _normalizeClass(["toggle-btn", {'toggle-right': _ctx.stateCollapsed}]),
                size: "medium",
                onClick: _ctx.toggleCollapsed
              }, {
                icon: _withCtx(() => [
                  (!_ctx.stateCollapsed)
                    ? (_openBlock(), _createBlock(_component_MenuUnfoldOutlined, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_MenuFoldOutlined, { key: 1 }))
                ]),
                _: 1
              }, 8, ["class", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.stateCollapsed)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btn-group",
                type: "primary",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toPublish && _ctx.toPublish(...args)))
              }, [
                _createVNode(_component_a_button, {
                  type: "primary",
                  class: "btn-publish"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.satisfiedEditPower ? "发布/更新应用" : "更新应用"), 1)
                  ]),
                  _: 1
                }),
                (_ctx.satisfiedEditPower)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("a", {
                        class: "toggle",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleBtnState && _ctx.toggleBtnState(...args)), ["stop"]))
                      }, _cache[18] || (_cache[18] = [
                        _createElementVNode("span", { class: "caret" }, null, -1)
                      ])),
                      (_ctx.btn_collpase)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            class: "add-btn",
                            onClick: _withModifiers(_ctx.addApp, ["stop"])
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode("添加空白应用")
                            ])),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createBlock(_component_a_button, {
                key: 2,
                onClick: _withModifiers(_ctx.addApp, ["stop"]),
                type: "primary",
                size: "medium",
                style: {"margin":"10px auto 10px"}
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_plus_outlined)
                ]),
                _: 1
              }, 8, ["onClick"])),
          (_ctx.satisfiedEditPower)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(["menu-title", {active: _ctx.isCategoryPage}]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toCategory && _ctx.toCategory(...args)))
              }, [
                (_ctx.stateCollapsed)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
                      _createVNode(_component_AppstoreOutlined, {
                        style: {"color":"#819199","font-size":"16px","padding-left":"20px"},
                        class: "left-icon"
                      }),
                      _cache[20] || (_cache[20] = _createElementVNode("span", { class: "title" }, "分类管理", -1))
                    ]))
                  : (_openBlock(), _createElementBlock("a", _hoisted_2, [
                      _createVNode(_component_AppstoreOutlined, {
                        style: {"color":"#819199","font-size":"22px","padding-left":"6px"},
                        class: "left-icon"
                      })
                    ]))
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.satisfiedEditPower)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["search-container", {'m-t': !_ctx.satisfiedEditPower, 'phone-search-container': !_ctx.stateCollapsed}])
            }, [
              (_ctx.stateCollapsed)
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 0,
                    style: {"border":"1px solid #e4e9f0"},
                    value: _ctx.search_text,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search_text) = $event)),
                    placeholder: "搜索您的应用",
                    allowClear: ""
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_search_outlined)
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "filter-type",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.phoneModalVisible=true;_ctx.phoneModalTitle='搜索'})
                  }, [
                    _createVNode(_component_search_outlined),
                    (_ctx.showSearchMark)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                      : _createCommentVNode("", true)
                  ])),
              _createElementVNode("div", {
                class: "filter-type",
                onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterOrder && _ctx.filterOrder(...args)), ["stop"]))
              }, [
                _cache[21] || (_cache[21] = _createElementVNode("img", {
                  src: _imports_0,
                  style: {"width":"20px","height":"20px"}
                }, null, -1)),
                (_ctx.showCollectMark)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: "filter-type",
                onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterType && _ctx.filterType(...args)), ["stop"]))
              }, [
                _cache[22] || (_cache[22] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                (_ctx.showFilterMark)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.order_show)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "filter-item",
                        onClick: ($event: any) => (_ctx.clickOrder(item)),
                        key: item.key
                      }, [
                        (_ctx.getterFilters.orderByColumn === item.key && _ctx.getterFilters.sort === item.sort)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.orderByColumn !== item.key || _ctx.getterFilters.sort !== item.sort}])
                        }, _toDisplayString(item.title), 3),
                        (item.sort==='ASC')
                          ? (_openBlock(), _createBlock(_component_SwapLeftOutlined, {
                              key: 1,
                              class: "desc"
                            }))
                          : (_openBlock(), _createBlock(_component_SwapRightOutlined, {
                              key: 2,
                              class: "desc"
                            }))
                      ], 8, _hoisted_9))
                    }), 128)),
                    (_ctx.satisfiedEditPower)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "checkbox-filter",
                          onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"]))
                        }, [
                          _createVNode(_component_a_checkbox, {
                            onChange: _withModifiers(_ctx.clickCollect, ["stop","prevent"]),
                            class: "collect",
                            checked: _ctx.collect_checked,
                            "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.collect_checked) = $event))
                          }, {
                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode("仅展示收藏 ")
                            ])),
                            _: 1
                          }, 8, ["onChange", "checked"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filter_show)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getterPlatformCounts, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "filter-item",
                        onClick: ($event: any) => (_ctx.clickFilter(item.title)),
                        key: item
                      }, [
                        (_ctx.getterFilters.appType === item.title)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_13))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.appType !== item.title}])
                        }, _toDisplayString(item.title ? item.title : '全部'), 3),
                        _createElementVNode("span", _hoisted_14, _toDisplayString(item.count), 1)
                      ], 8, _hoisted_12))
                    }), 128)),
                    (_ctx.satisfiedEditPower)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _cache[24] || (_cache[24] = _createElementVNode("span", null, "应用分类", -1)),
                            _createElementVNode("div", {
                              class: "right-toggle",
                              onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleState && _ctx.toggleState(...args)), ["stop"]))
                            }, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                style: _normalizeStyle([{"width":"14px","cursor":"pointer"}, {transform: _ctx.toggleType === 'category' ? 'rotate(180deg)': 'rotate(-90deg)'}])
                              }, null, 4)
                            ])
                          ]),
                          _withDirectives(_createElementVNode("div", {
                            class: "category-box",
                            style: _normalizeStyle(_ctx.maxHeight)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([{id: -1, categoryName: '全部'}, ..._ctx.categoryList], (item) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: item.id
                              }, [
                                ((item.id === -1) || item.appCount > 0)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      class: "filter-item",
                                      onClick: ($event: any) => (_ctx.clickCategory(item.id)),
                                      key: item.id
                                    }, [
                                      (_ctx.getterFilters.categoryId === item.id)
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.categoryId !== item.id}])
                                      }, _toDisplayString(item.categoryName), 3),
                                      _createElementVNode("span", _hoisted_19, _toDisplayString(item.id === -1 ? _ctx.all_count : item.appCount), 1)
                                    ], 8, _hoisted_17))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ], 4), [
                            [_vShow, _ctx.toggleType === 'developer']
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.satisfiedEditPower && _ctx.getterAppEditors && _ctx.getterAppEditors.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _cache[25] || (_cache[25] = _createElementVNode("span", null, "开发者", -1)),
                            _createElementVNode("div", {
                              class: "right-toggle",
                              onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleState && _ctx.toggleState(...args)), ["stop"]))
                            }, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                style: _normalizeStyle([{"width":"14px"}, {transform: _ctx.toggleType === 'developer' ? 'rotate(180deg)': 'rotate(-90deg)'}])
                              }, null, 4)
                            ])
                          ]),
                          _withDirectives(_createElementVNode("div", {
                            class: "category-box",
                            style: _normalizeStyle(_ctx.maxHeight)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([{userId: -1, userName: '全部', count: _ctx.all_count}, ..._ctx.getterAppEditors], (item) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: item.userId
                              }, [
                                ((item.userId === -1) || item.count > 0)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "filter-item",
                                      onClick: ($event: any) => (_ctx.clickEditor(item.userId))
                                    }, [
                                      (_ctx.getterFilters.userId === item.userId)
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.userId !== item.userId}])
                                      }, _toDisplayString(item.userName), 3),
                                      _createElementVNode("span", _hoisted_24, _toDisplayString(item.count), 1)
                                    ], 8, _hoisted_22))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ], 4), [
                            [_vShow, _ctx.toggleType === 'category']
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_a_spin, { spinning: _ctx.spinning }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterAppList(_ctx.search_text), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["menu-title sidebar-app", {open: item.appId === _ctx.currentAppId && _ctx.isAppPage}]),
                    key: item.appId
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass(["item", {'phone-height': !_ctx.stateCollapsed}]),
                      onClick: ($event: any) => (_ctx.chooseApp(item))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["app-icon", {'m-l-0': !_ctx.stateCollapsed}])
                      }, [
                        _createVNode(_component_a_badge, {
                          dot: item.unreadMessage && !_ctx.nativeReadList.includes(item.appId),
                          style: {"vertical-align":"top"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_image, {
                              preview: false,
                              width: 36,
                              height: 36,
                              src: item.appIcon,
                              fallback: _ctx.getDefault(item.appType)
                            }, {
                              placeholder: _withCtx(() => [
                                _createVNode(_component_a_image, {
                                  src: _ctx.getDefault(item.appType),
                                  width: 36,
                                  preview: false
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1032, ["src", "fallback"])
                          ]),
                          _: 2
                        }, 1032, ["dot"])
                      ], 2),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["app-title", {empty: !item.appVersionCount}])
                        }, _toDisplayString(item.appName), 3),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(item.appType), 1)
                      ])
                    ], 10, _hoisted_26)
                  ], 2))
                }), 128))
              ]),
              _: 1
            }, 8, ["spinning"])
          ], 512)
        ]),
        _: 1
      }, 8, ["class", "width"]),
      _createVNode(_component_a_layout_content, {
        class: _normalizeClass(["wrapper main-wrapper row-padding", {'p-0-vertical': _ctx.isMobile, 'op-3': _ctx.isMobile && _ctx.stateCollapsed}]),
        style: _normalizeStyle({marginLeft: _ctx.leftMargin + 'px'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["class", "style"]),
      _createVNode(_component_app_modal, {
        visible: _ctx.visible,
        modalTitle: _ctx.modalTitle,
        formState: _ctx.formState,
        download_user_ids: [],
        edit_user_ids: [],
        onReset: _ctx.reset
      }, null, 8, ["visible", "modalTitle", "formState", "onReset"]),
      _createVNode(_component_a_modal, {
        title: _ctx.phoneModalTitle,
        visible: _ctx.phoneModalVisible,
        "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.phoneModalVisible) = $event)),
        footer: _ctx.phoneModalTitle === '搜索' ? undefined : null,
        "ok-text": "确认",
        "cancel-text": "取消",
        onOk: _ctx.phoneModalOk,
        onCancel: _ctx.phoneModalCancel
      }, {
        default: _withCtx(() => [
          (_ctx.phoneModalTitle==='搜索')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                style: {"border":"1px solid #e4e9f0"},
                value: _ctx.search_text,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.search_text) = $event)),
                placeholder: "搜索您的应用",
                allowClear: ""
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_search_outlined)
                ]),
                _: 1
              }, 8, ["value"]))
            : (_ctx.phoneModalTitle==='排序')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderList, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "filter-item",
                      onClick: ($event: any) => (_ctx.clickOrder(item)),
                      key: item.key
                    }, [
                      (_ctx.getterFilters.orderByColumn === item.key && _ctx.getterFilters.sort === item.sort)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.orderByColumn !== item.key || _ctx.getterFilters.sort !== item.sort}])
                      }, _toDisplayString(item.title), 3),
                      (item.sort==='ASC')
                        ? (_openBlock(), _createBlock(_component_SwapLeftOutlined, {
                            key: 1,
                            class: "desc"
                          }))
                        : (_openBlock(), _createBlock(_component_SwapRightOutlined, {
                            key: 2,
                            class: "desc"
                          }))
                    ], 8, _hoisted_29))
                  }), 128)),
                  (_ctx.satisfiedEditPower)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "checkbox-filter",
                        onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        _createVNode(_component_a_checkbox, {
                          onChange: _withModifiers(_ctx.clickCollect, ["stop","prevent"]),
                          class: "collect",
                          checked: _ctx.collect_checked,
                          "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.collect_checked) = $event))
                        }, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode("仅展示收藏 ")
                          ])),
                          _: 1
                        }, 8, ["onChange", "checked"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_ctx.phoneModalTitle==='筛选')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getterPlatformCounts, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "filter-item",
                        onClick: ($event: any) => (_ctx.clickFilter(item.title)),
                        key: item
                      }, [
                        (_ctx.getterFilters.appType === item.title)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.appType !== item.title}])
                        }, _toDisplayString(item.title ? item.title : '全部'), 3),
                        _createElementVNode("span", _hoisted_33, _toDisplayString(item.count), 1)
                      ], 8, _hoisted_31))
                    }), 128)),
                    (_ctx.satisfiedEditPower)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _cache[27] || (_cache[27] = _createElementVNode("span", null, "应用分类", -1)),
                            _createElementVNode("div", {
                              class: "right-toggle",
                              onClick: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleState && _ctx.toggleState(...args)), ["stop"]))
                            }, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                style: _normalizeStyle([{"width":"14px","cursor":"pointer"}, {transform: _ctx.toggleType === 'category' ? 'rotate(180deg)': 'rotate(-90deg)'}])
                              }, null, 4)
                            ])
                          ]),
                          _withDirectives(_createElementVNode("div", {
                            class: "category-box",
                            style: _normalizeStyle(_ctx.maxHeight)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([{id: -1, categoryName: '全部'}, ..._ctx.categoryList], (item) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: item.id
                              }, [
                                ((item.id === -1) || item.appCount > 0)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      class: "filter-item",
                                      onClick: ($event: any) => (_ctx.clickCategory(item.id)),
                                      key: item.id
                                    }, [
                                      (_ctx.getterFilters.categoryId === item.id)
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_37))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.categoryId !== item.id}])
                                      }, _toDisplayString(item.categoryName), 3),
                                      _createElementVNode("span", _hoisted_38, _toDisplayString(item.id === -1 ? _ctx.all_count : item.appCount), 1)
                                    ], 8, _hoisted_36))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ], 4), [
                            [_vShow, _ctx.toggleType === 'developer']
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.satisfiedEditPower && _ctx.getterAppEditors && _ctx.getterAppEditors.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[28] || (_cache[28] = _createElementVNode("span", null, "开发者", -1)),
                            _createElementVNode("div", {
                              class: "right-toggle",
                              onClick: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleState && _ctx.toggleState(...args)), ["stop"]))
                            }, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                style: _normalizeStyle([{"width":"14px"}, {transform: _ctx.toggleType === 'developer' ? 'rotate(180deg)': 'rotate(-90deg)'}])
                              }, null, 4)
                            ])
                          ]),
                          _withDirectives(_createElementVNode("div", {
                            class: "category-box",
                            style: _normalizeStyle(_ctx.maxHeight)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([{userId: -1, userName: '全部', count: _ctx.all_count}, ..._ctx.getterAppEditors], (item) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: item.userId
                              }, [
                                ((item.userId === -1) || item.count > 0)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "filter-item",
                                      onClick: ($event: any) => (_ctx.clickEditor(item.userId))
                                    }, [
                                      (_ctx.getterFilters.userId === item.userId)
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_42))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["title", {'not-choose': _ctx.getterFilters.userId !== item.userId}])
                                      }, _toDisplayString(item.userName), 3),
                                      _createElementVNode("span", _hoisted_43, _toDisplayString(item.count), 1)
                                    ], 8, _hoisted_41))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ], 4), [
                            [_vShow, _ctx.toggleType === 'category']
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "visible", "footer", "onOk", "onCancel"])
    ]),
    _: 1
  }))
}