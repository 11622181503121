
import {Vue, Options, Prop} from 'vue-property-decorator';
import {splitCommitTime, replaceCategories} from "@/utils/utils";
import {
  EditOutlined,
  PlusOutlined,
  CopyOutlined,
  DownOutlined,
  SendOutlined,
  DeleteOutlined,
  SettingOutlined
} from '@ant-design/icons-vue';
import {
  Drawer,
  Modal,
  Upload,
  Button,
  Tag,
  Skeleton,
  Tooltip,
  Space,
  Switch,
  Select,
  Image,
  Popconfirm,
  Dropdown,
  Menu
} from 'ant-design-vue'
import WorkFlowUpload from "@/components/WorkFlowUpload.vue";
import VueEasyLightbox from "@/components/vue-light-box/VueLightBox.vue"
import {E_Lark_type, sendLarkNotification} from "@/utils/redirect/lark_notification";
import {Getter} from "vuex-class";
import {GETTER_CURRENT_APP_ID, GETTER_LEVEL} from "@/store/constants/user_types";
import {sendMultiLarkMessage} from "@/api/service/notification";
import Toast from "@/utils/toast";

@Options({
  components: {
    APopconfirm: Popconfirm,
    DeleteOutlined,
    AImage: Image,
    AImagePreviewGroup: Image.PreviewGroup,
    SendOutlined,
    CopyOutlined,
    EditOutlined,
    SettingOutlined,
    PlusOutlined,
    DownOutlined,
    ADrawer: Drawer,
    AModal: Modal,
    AUpload: Upload,
    AButton: Button,
    ATag: Tag,
    ASkeleton: Skeleton,
    ATooltip: Tooltip,
    WorkFlowUpload,
    VueEasyLightbox,
    ASpace: Space,
    ASwitch: Switch,
    ASelect: Select,
    ASelectOption: Select['Option'],
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu['Item'],
  }
})
export default class WorkFlowDetail extends Vue {
  @Prop()
  list?: any[]; // commit历史
  @Prop()
  detail?: any;
  @Prop()
  loading?: boolean;
  @Prop()
  versionList?: any[]; // 版本历史
  @Getter(GETTER_CURRENT_APP_ID)
  private currentAppId: any;
  @Getter(GETTER_LEVEL)
  private roleLevel: any;

  visible = false;
  previewVisible = false;
  imgs: string[] = [];
  current_commit: any = null;
  previewIndex = 0;
  showCategory = ['更新日志', '视频/截图', '备注'];
  showOptions = ['更新日志', '视频/截图', '备注'];
  showAll = false;
  selectedCommitter = null;
  tempEndCommit = null;
  customSendLark = false;

  // 判断是否满足编辑权限
  get satisfiedEditPower() {
    return this.roleLevel === 100;
  }

  get userList () {
    const remarksSet = new Set();

    // 遍历 data 数组并提取 remark
    this.list?.forEach(item => {
      console.log("item.remark", item.remark);
      if (item.remark) {
        remarksSet.add(item.remark);
      }
    });
    // 返回一个去重后的 remark 数组
    return Array.from(remarksSet);
  }

  get showList() {
    return this.selectedCommitter ? this.list?.filter((item: any) => item.remark === this.selectedCommitter) : this.list
  }

  CommitFilter(input: string, option: any) {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  serviceTimeToDateAndTime(serviceTime: string) {
    if (!serviceTime) {
      return null;
    }
    return splitCommitTime(serviceTime);
  }

  editSnapshot(commit: any) {
    this.current_commit = commit;
    this.visible = true;
  }

  sendLark(commitId: string, workflowId: string, branch: string) {
    sendLarkNotification({
      appId: this.currentAppId,
      receiveType: E_Lark_type.commit,
      commitId,
      workflowId,
      branch
    }, false);
  }

  async sendMultiLark(commitId: string) {
    try {
      console.log("sendMultiLark", commitId);
      if (!this.tempEndCommit) {
        Toast.show("warning", "请选择想要推送的日志截止点");
        return;
      }
      const list = this.showList || [];
      const startIndex = list.findIndex((v) => v.commitId === commitId);
      const endIndex = list.findIndex((v) => v.commitId === this.tempEndCommit);
      const commitIds = list.filter((v, index) => index >= startIndex && index <= endIndex).map((item: any) => item.commitId);
      await sendMultiLarkMessage(this.currentAppId, this.detail?.id, commitIds);
      this.tempEndCommit = null;
      Toast.show("success", "消息已推送至飞书");
    } catch (e) {
      this.tempEndCommit = null;
      Toast.show("error", e);
    }
  }

  getBranchName(str: string) {
    return str.split('/').pop();
  }

  get showCommit() {
    return this.showCategory.includes('更新日志');
  }

  get showMedia() {
    return this.showCategory.includes('视频/截图');
  }

  get showDescription() {
    return this.showCategory.includes('备注');
  }

  editWorkflow() {
    this.$emit('editWorkflow', this.detail);
  }

  onPreview(imgs: string[], index: number) {
    this.imgs = imgs;
    this.previewIndex = index;
    this.previewVisible = true;
  }

  chooseOption(o: string) {
    this.showCategory = this.showCategory.some(item => item === o)
        ? this.showCategory.filter(item => item !== o)
        : [...this.showCategory, o];
  }

  changeDisplayRange(v: any) {
    this.$emit('onNext', this.detail?.id, true, !v);
  }

  onNext() {
    this.$emit('onNext', this.detail?.id, true);
  }

  isVideoFile(url: string) {
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi']
    return videoExtensions.some(ext => url && url.toLowerCase().includes(ext))
  }

  getImgFiles(list: string[]) {
    return list?.filter(url =>
        !this.isVideoFile(url) &&
        /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url)
    ) || [];
  }

  getVideoFiles(list: string[]) {
    return list?.filter(url =>
        this.isVideoFile(url)
    ) || [];
  }

  transformCommit(commitMessage: string) {
    return replaceCategories(commitMessage)
  }

  async delConfirm(id: string) {
    this.$emit("deleteWorkflow", id);
  }
}
