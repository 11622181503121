
import { Vue, Options, Prop, Watch } from 'vue-property-decorator';
import {Modal, Button, Drawer, Upload, Tag, Skeleton, Typography, Space, Input, Select, Image} from 'ant-design-vue';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import Toast from "@/utils/toast";
import config from "@/config";
import {Getter} from "vuex-class";
import {GETTER_TOKEN} from "@/store/constants/user_types";
import VueEasyLightbox from "@/components/vue-light-box/VueLightBox.vue"
import {bindCommit, updateCommitMessage} from "@/api/service/workflow";

@Options({
  components: {
    ASelect: Select,
    ASelectOption: Select['Option'],
    PlusOutlined,
    UploadOutlined,
    ADrawer: Drawer,
    AModal: Modal,
    AUpload: Upload,
    AButton: Button,
    ATag: Tag,
    ASkeleton: Skeleton,
    ASpace: Space,
    ATypographyTitle: Typography['Title'],
    ATextarea: Input['TextArea'],
    VueEasyLightbox,
    AImage: Image,
    AImagePreviewGroup: Image.PreviewGroup,
  },
})
export default class WorkFlowUpload extends Vue {
  @Prop() visible?: boolean;
  @Prop({ type: Object, default: () => null })
  readonly defaultValue!: any;
  @Prop()
  versionList?: any[];

  clickIsVideo = false;

  @Getter(GETTER_TOKEN)
  private token: any;

  imgPreviewVisible = false;
  videoPreviewVisible = false;
  submitLoading = false;
  imgs: any[] = [];
  previewIndex = 0;

  fileList: any[] = [];

  description = '';
  current_app_version_id = '';

  imgFiles: string[] = [];
  videoFiles: string[] = [];

  get iconUploadUrl() {
    return config.BASE_URL + "api/upload"
  }

  get uploadHeader() {
    return {
      token: this.token
    }
  }

  get supplyData() {
    return {
      type: "app"
    }
  }

  @Watch('defaultValue', { immediate: true, deep: true })
  onDefaultValueChange(newVal: any) {
    if (newVal) {
      const fileList = newVal?.fileUrls?.map((file: string) => ({
        uid: Math.random().toString(36).substr(2),
        name: file,
        url: file,
        thumbUrl: this.getThumbUrl(file),
        status: 'done' // 标记为已上传状态
      })) || [];
      fileList.sort((a: any, b: any) => {
        const aIsVideo = this.isVideoFile(a.url);
        const bIsVideo = this.isVideoFile(b.url);

        if (aIsVideo && !bIsVideo) {
          return -1; // a 是视频，b 不是视频，a 排在前面
        } else if (!aIsVideo && bIsVideo) {
          return 1; // b 是视频，a 不是视频，b 排在前面
        } else {
          return 0; // 两者都是视频或都不是视频，保持原来的顺序
        }
      });
      this.fileList = fileList
      this.description = newVal?.description;
      this.current_app_version_id = newVal?.appVersionId;
    }
  }

  getThumbUrl(url: any) {
    const fileExtension = url.split('.').pop()?.toLowerCase(); // 获取文件扩展名，并转为小写

    // 判断文件扩展名来确定文件类型，并返回对应的占位图
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
      return url; // 如果是图片，返回图片的 URL 作为缩略图
    } else if (fileExtension === 'mp4') {
      return require('@/assets/mp4.svg'); // mp4视频类型占位图
    } else if (fileExtension === 'webm') {
      return require('@/assets/webm.svg'); // webm视频类型占位图
    } else if (fileExtension === 'avi') {
      return require('@/assets/avi.svg'); // avi视频类型占位图
    } else if (fileExtension === 'mov') {
      return require('@/assets/mov.svg'); // mov视频类型占位图
    }

    return ''; // 如果无法识别文件类型，返回空
  }


  async beforeUpload(file: any) {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
    const isVideo = file.type === 'video/mp4' || file.type === 'video/webm' || file.type === 'video/quicktime';

    if (!isImage && !isVideo) {
      Toast.show("warning", "必须上传图片或视频类型的文件");
      return Upload.LIST_IGNORE;
    }

    // // 视频校验
    // if (isVideo) {
    //   try {
    //
    //   } catch (e) {
    //     return Upload.LIST_IGNORE;
    //   }
    // }

    return true;
  }

  handleChange(info: any) {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      const index = this.fileList.findIndex((v) => v.uid === info.file.uid);
      this.fileList.splice(index, 1, {
        name: info.file.name,
        uid: info.file.uid,
        url: info.file.response.data.url,
        thumbUrl: this.getThumbUrl(info.file.response.data.url),
      })
    }
    if (info.file.status === 'error') {
      Toast.show("error", 'upload error')
    }
  }

  onPreview(val: any) {
    // 如果点击的事图片
    if (!this.isVideoFile(val.url)) {
      const imgFiles = this.fileList.filter((item: any) => !this.isVideoFile(item.url));
      this.previewIndex = imgFiles.findIndex((v: any) => v.uid === val.uid);
      this.imgFiles = imgFiles.map((item) => item.url);
      this.imgPreviewVisible = true;
    } else {
      const videoFiles = this.fileList.filter((item: any) => this.isVideoFile(item.url));
      this.previewIndex = videoFiles.findIndex((v: any) => v.uid === val.uid);
      this.videoFiles = videoFiles.map((item: any) => item.url);
      this.videoPreviewVisible = true;
    }
  }

  isVideoFile(url: string) {
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi']
    return videoExtensions.some(ext => url.toLowerCase().includes(ext))
  }

  // 处理 visible 状态改变
  onClose(newVisible: boolean) {
    this.$emit('update:visible', newVisible);
  }

  async onSave() {
    try {
      const imgList = this.fileList.map((item: any) => (item.url));
      this.submitLoading = true;
      await updateCommitMessage(this.defaultValue.id, this.description, imgList);
      if (this.current_app_version_id !== this.defaultValue?.appVersionId) {
        await bindCommit(this.defaultValue.commitId, this.current_app_version_id);
      }
      this.submitLoading = false;
      this.onClose(false);
      this.$emit('onNext');
    } catch (e) {
      console.log("onSave", e);
      this.submitLoading = false;
      Toast.show("error", e)
    }
  }
}
