// export const getRanNumChat = () => {
//   const result = [];
//   for (let i = 0; i < 4; i++) {
//     const ranNum = Math.ceil(Math.random() * 25);
//     result.push(String.fromCharCode(Math.random() < 0.5 ? 97 : 65 + ranNum));
//   }
//   return result.join('');
// }

export function getRanNumChat(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let randomString = '';
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

export const getRandomNum = (num) => {
  let randomNum = '';
  for (let i = 0; i < num; i++) {
    randomNum += String(Math.floor(Math.random() * 10))
  }
  return randomNum;
}

export function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}
let isIosCache = null; // default true
export function isIos() {
  if (isIosCache !== null) {
    return isIosCache;
  } else {
    const u = window.navigator.userAgent;
    const isHigherVersionPad =
      window.navigator.platform === "MacIntel" &&
      window.navigator.maxTouchPoints > 1;
    const isiOS =
      !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isHigherVersionPad; // ios终端
    isIosCache = isiOS;
    return isiOS;
  }
}

export const objectToQuery = (obj, encode = true) => {
  const pushArr = [];
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    for (const key in obj) {
      if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
        pushArr.push(
          `${key}=${
            encode
              ? encodeURIComponent(JSON.stringify(obj[key]))
              : JSON.stringify(obj[key])
          }`
        );
      } else if (obj[key] !== undefined) {
        pushArr.push(
          `${key}=${encode ? encodeURIComponent(obj[key]) : obj[key]}`
        );
      }
    }
    return pushArr.join("&");
  } else {
    throw new Error("expect object type param");
  }
};

export const getSuffix = (file_path) => {
  return file_path.substring(file_path.lastIndexOf("."));
}

export function throttle(func, wait, immediate) {
  let timerId = null; // 定时器 ID

  return function(...args) {
    if (timerId !== null) {
      clearTimeout(timerId); // 清除定时器
    }
    if (immediate && timerId === null) {
      func.apply(this, args); // 立即执行函数
    }
    timerId = setTimeout(() => {
      if (!immediate) {
        func.apply(this, args); // 延迟执行函数
      }
      timerId = null; // 清除定时器 ID
    }, wait); // 延迟执行函数
  };
}

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ['iphone', 'ipod', 'android', 'blackberry', 'windows phone'];
  return mobileKeywords.some(keyword => userAgent.includes(keyword));
}

export function splitCommitTime(commitTime) {
  // 创建一个 Date 对象
  const dateObj = new Date(commitTime);

  // 获取日期部分 (yyyy-mm-dd)
  const datePart = dateObj.toISOString().split('T')[0];

  // 获取时间部分 (hh:mm:ss)
  const timePart = commitTime.split(' ')[1];

  return {
    date: datePart,
    time: timePart
  };
}

export function getVideoDimensions(videoUrl) {
  console.log("getVideoDimensions");
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    // 监听元数据加载完毕的事件
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight
      });
    };

    // 处理加载错误
    video.onerror = () => {
      reject(new Error("Failed to load video metadata"));
    };

    // 设置视频源和预加载元数据
    video.src = videoUrl;
    video.preload = 'metadata';
  });
}

const CATEGORY_MAPPING = {
  "feat": "🚀 新增/变更",
  "fix": "🐛 Bug修改",
  "docs": "📖 文档",
  "doc": "📖 文档",
  "perf": "⚡ 性能优化",
  "style": "🎨 代码样式",
  "refactor": "♻️ 代码重构",
  "test": "🧪 测试相关",
  "chore": "⚙️ 构建过程或辅助工具的变动",
  "revert": "◀️ 回退",
  "other": "💼 其他改动"
};

export function replaceCategories(inputStr) {
  // 使用正则表达式，按顺序替换所有的关键词
  return inputStr.replace(/\b(feat|fix|docs|doc|perf|style|refactor|test|chore|revert|other)\b/g, (match) => {
    return CATEGORY_MAPPING[match] || match;
  });
}
