import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.modalTitle,
    visible: _ctx.visible,
    "onUpdate:visible": _ctx.handleVisibleChange,
    onOk: _ctx.handleOk,
    okText: "确认",
    cancelText: "取消",
    centered: "",
    confirmLoading: _ctx.confirmLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol,
        autocomplete: "off",
        ref: "form",
        model: _ctx.formState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "工作流名称",
            name: "tabName",
            rules: [{ required: true, message: '请输入工作流名称!' }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.tabName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.tabName) = $event)),
                placeholder: "请输入工作流名称"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "工作流类型",
            name: "repoType",
            rules: [{ required: true, message: '请选择工作流类型!' }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formState.repoType,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.repoType) = $event)),
                placeholder: "请选择工作流类型"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: item.key,
                      key: item.key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "仓库类型",
            name: "manageType",
            rules: [{ required: true, message: '请选择仓库类型!' }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formState.manageType,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.manageType) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.repoList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: item.key,
                      key: item.key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_a_form_item, {
            label: "仓库地址",
            name: "repoPath",
            rules: _ctx.formState.manageType !== _ctx.repoTypeEnum.OTHER ? [{ required: true, message: '请输入仓库地址!' }] : []
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.repoPath,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.repoPath) = $event)),
                placeholder: "请输入仓库地址"
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["rules"]), [
            [_vShow, _ctx.formState.manageType !== _ctx.repoTypeEnum.OTHER]
          ]),
          _withDirectives(_createVNode(_component_a_form_item, {
            label: "仓库分支",
            name: "repoBranch"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.defaultBranch,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.defaultBranch) = $event)),
                placeholder: "请输入仓库分支"
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.formState.manageType !== _ctx.repoTypeEnum.OTHER]
          ]),
          _withDirectives(_createVNode(_component_a_form_item, {
            label: "提交人",
            name: "repoBranch"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formState.committers,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.committers) = $event)),
                mode: "multiple",
                style: {"width":"100%"},
                placeholder: "请选择提交人（可多选）",
                filterOption: _ctx.userNameFilter
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowUserList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item,
                      value: item,
                      title: item
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.formState.manageType !== _ctx.repoTypeEnum.OTHER]
          ]),
          _createVNode(_component_a_form_item, { label: "自动通知" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_switch, {
                size: "small",
                checked: _ctx.formState.autoPush,
                "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.autoPush) = $event))
              }, null, 8, ["checked"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label-col", "wrapper-col", "model"])
    ]),
    _: 1
  }, 8, ["title", "visible", "onUpdate:visible", "onOk", "confirmLoading"]))
}