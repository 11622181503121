import {BaseApi} from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";

export const editAppleAccount = async (params, isEdit) => {
  try {
    const result = await BaseApi.post(isEdit ? PATH_URL.workflowUpdate : PATH_URL.workflow, params);
    console.log("result.data", result.data);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getCommitLogList = async (workflowId, onlyCliff = true) => {
  try {
    const result = await BaseApi.get(PATH_URL.commitLogList, {
      params: {
        workflowId,
        onlyCliff
      }
    });
    return result ? result.data : [];
  } catch (e) {
    return Promise.reject(e);
  }
}

export const fetchWorkflowList = async (appId) => {
  try {
    const result = await BaseApi.get(PATH_URL.workflow, {
      params: {
        appId
      }
    });
    return result ? result.data : [];
  } catch (e) {
    return Promise.reject(e);
  }
}

export const updateCommitMessage = async (id, description, fileUrls) => {
  try {
    const result = await BaseApi.post(PATH_URL.updateCommit, {
      id,
      description,
      fileUrls: JSON.stringify(fileUrls)
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const bindCommit = async (commitId, appVersionId) => {
  try {
    const result = await BaseApi.post(PATH_URL.bind_commit, {
      commitId,
      appVersionId
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getAppVersion = async (appId) => {
  try {
    const result = await BaseApi.get(PATH_URL.simple_version_list, {
      params: {
        appId
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getCommitDetail = async (commitId, workflowId) => {
  try {
    const result = await BaseApi.get(PATH_URL.commit_detail, {
      params: {
        commitId,
        workflowId
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const delCommit = async (id) => {
  try {
    const result = await BaseApi.delete(PATH_URL.workflow, {
      params: {
        id
      }
    });
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const updateWorkflowOrder = async (list) => {
  try {
    const result = await BaseApi.post(PATH_URL.workflowUpdateOrder, {
      list
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getWorkflowUserList = async () => {
  try {
    const result = await BaseApi.get(PATH_URL.commit_username_list);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
