import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "display-flex align-items-center",
  style: {"justify-content":"space-between"}
}
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = {
  class: "display-flex align-items-center flex-wrap",
  style: {"margin":"20px 0"}
}
const _hoisted_4 = { class: "display-flex align-items-center flex-wrap" }
const _hoisted_5 = { class: "filter-content" }
const _hoisted_6 = { class: "item-list filter-single-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "ivu-checkbox-wrapper ivu-checkbox-wrapper-checked ivu-checkbox-default" }
const _hoisted_9 = {
  class: "display-flex align-items-center flex-wrap",
  style: {"margin-right":"10px"}
}
const _hoisted_10 = { class: "display-flex align-items-center" }
const _hoisted_11 = {
  key: 1,
  class: "record-list"
}
const _hoisted_12 = { class: "update-date" }
const _hoisted_13 = {
  key: 0,
  class: "version"
}
const _hoisted_14 = { class: "date" }
const _hoisted_15 = { class: "time" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "update-content" }
const _hoisted_18 = { class: "subtitle-wrap" }
const _hoisted_19 = { class: "update-date-mobile" }
const _hoisted_20 = {
  class: "display-flex",
  style: {"justify-content":"space-between"}
}
const _hoisted_21 = { class: "display-flex" }
const _hoisted_22 = { class: "date" }
const _hoisted_23 = {
  class: "time",
  style: {"margin-left":"8px"}
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  class: "display-flex",
  style: {"justify-content":"space-between"}
}
const _hoisted_26 = { class: "name" }
const _hoisted_27 = {
  key: 0,
  class: "version-mobile"
}
const _hoisted_28 = { class: "subtitle" }
const _hoisted_29 = {
  key: 0,
  class: "subtitle",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_30 = { class: "upd-log" }
const _hoisted_31 = {
  key: 0,
  class: "baseinfo-title"
}
const _hoisted_32 = {
  key: 1,
  class: "note app-describe app-describe-showTxt"
}
const _hoisted_33 = { class: "box-description" }
const _hoisted_34 = { class: "description pre_wrap" }
const _hoisted_35 = {
  key: 0,
  class: "baseinfo-title"
}
const _hoisted_36 = {
  key: 1,
  class: "note app-describe app-describe-showTxt"
}
const _hoisted_37 = { class: "box-description" }
const _hoisted_38 = { class: "screenshot-list" }
const _hoisted_39 = { class: "screenshot-box" }
const _hoisted_40 = ["src"]
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { class: "app-describe" }
const _hoisted_43 = { class: "box-description" }
const _hoisted_44 = { class: "description" }
const _hoisted_45 = {
  key: 2,
  class: "no-info-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_ASpace = _resolveComponent("ASpace")!
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_SendOutlined = _resolveComponent("SendOutlined")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_work_flow_upload = _resolveComponent("work-flow-upload")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ASpace, { size: "middle" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.detail?.tabName), 1),
          _createVNode(_component_a_tooltip, {
            title: `修改${_ctx.detail?.tabName}配置`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditOutlined, {
                style: {"cursor":"pointer"},
                onClick: _ctx.editWorkflow
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["title"]),
          (_ctx.satisfiedEditPower)
            ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                key: 0,
                title: `请确认是否删除${_ctx.detail?.tabName}?`,
                "ok-text": "确认",
                "cancel-text": "取消",
                onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.delConfirm(_ctx.detail?.id)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DeleteOutlined, { style: {"cursor":"pointer"} })
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_dropdown, null, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                    class: "display-flex align-items-center"
                  }, [
                    _cache[11] || (_cache[11] = _createTextVNode(" 自定义发送通知")),
                    _createVNode(_component_a_switch, {
                      size: "small",
                      checked: _ctx.customSendLark,
                      "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customSendLark) = $event)),
                      style: {"margin-left":"8px"}
                    }, null, 8, ["checked"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_SettingOutlined, { style: {"cursor":"pointer","font-size":"16px"} })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "filter-type" }, "显示信息", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showOptions, (o, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (_ctx.chooseOption(o))
              }, [
                _createElementVNode("label", _hoisted_8, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["ivu-checkbox", {'ivu-checkbox-checked': _ctx.showCategory.includes(o)}])
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("span", { class: "ivu-checkbox-inner" }, null, -1),
                    _createElementVNode("input", {
                      type: "checkbox",
                      class: "ivu-checkbox-input"
                    }, null, -1)
                  ]), 2),
                  _createTextVNode(" " + _toDisplayString(o), 1)
                ])
              ], 8, _hoisted_7))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "filter-type" }, "提交人", -1)),
        _createVNode(_component_a_select, {
          value: _ctx.selectedCommitter,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCommitter) = $event)),
          placeholder: "请选择",
          style: {"width":"120px"},
          "show-search": "",
          filterOption: _ctx.CommitFilter,
          allowClear: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userList, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                value: item,
                key: item,
                title: item
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item), 1)
                ]),
                _: 2
              }, 1032, ["value", "title"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "filterOption"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "filter-type" }, "是否显示已过滤的日志", -1)),
        _createVNode(_component_a_switch, {
          size: "small",
          checked: _ctx.showAll,
          "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showAll) = $event)),
          onChange: _ctx.changeDisplayRange
        }, null, 8, ["checked", "onChange"])
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (!_ctx.loading && _ctx.showList && _ctx.showList.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showList, (item, item_index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id
              }, [
                _createElementVNode("div", _hoisted_12, [
                  (item.tagName)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                        _createVNode(_component_a_tag, { color: "success" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getBranchName(item.tagName)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.serviceTimeToDateAndTime(item.commitTime)?.date), 1),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.serviceTimeToDateAndTime(item.commitTime)?.time), 1),
                  _createVNode(_component_ASpace, { size: "middle" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_tooltip, { title: "修改/补充" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_EditOutlined, {
                            style: {"cursor":"pointer","color":"#1890ff"},
                            onClick: ($event: any) => (_ctx.editSnapshot(item))
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_a_tooltip, { title: "发送飞书通知" }, {
                        default: _withCtx(() => [
                          (!_ctx.customSendLark)
                            ? (_openBlock(), _createBlock(_component_SendOutlined, {
                                key: 0,
                                style: {"cursor":"pointer","color":"#1890ff"},
                                onClick: ($event: any) => (_ctx.sendLark(item.commitId, _ctx.detail?.id, item.branch))
                              }, null, 8, ["onClick"]))
                            : (_openBlock(), _createBlock(_component_a_dropdown, {
                                key: 1,
                                trigger: ['click']
                              }, {
                                overlay: _withCtx(() => [
                                  _createVNode(_component_a_menu, null, {
                                    default: _withCtx(() => [
                                      (item_index < _ctx.showList.length - 1)
                                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", {
                                                onClick: _withModifiers(($event: any) => (_ctx.sendMultiLark(item.commitId)), ["prevent"])
                                              }, [
                                                _cache[16] || (_cache[16] = _createTextVNode(" 自定义发送通知：当前日志到 ")),
                                                _createVNode(_component_a_select, {
                                                  value: _ctx.tempEndCommit,
                                                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tempEndCommit) = $event)),
                                                  placeholder: "请选择",
                                                  style: {"width":"100px"},
                                                  "show-search": "",
                                                  filterOption: _ctx.CommitFilter,
                                                  allowClear: "",
                                                  onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showList.filter((v, index) => index > item_index), (item) => {
                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                        value: item.commitId,
                                                        key: item.commitId,
                                                        title: item.commitId?.slice(0, 7)
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(item.commitId?.slice(0, 7)), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value", "title"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["value", "filterOption"]),
                                                _cache[17] || (_cache[17] = _createTextVNode(" 的所有日志 "))
                                              ], 8, _hoisted_16)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_SendOutlined, { style: {"cursor":"pointer","color":"#1890ff"} })
                                ]),
                                _: 2
                              }, 1024))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["icon-wrapper", {'tag': !!item.tagName}])
                }, null, 2),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.serviceTimeToDateAndTime(item.commitTime)?.date), 1),
                          _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.serviceTimeToDateAndTime(item.commitTime)?.time), 1)
                        ]),
                        _createVNode(_component_ASpace, { size: "middle" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_tooltip, { title: "修改/补充" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_EditOutlined, {
                                  style: {"cursor":"pointer","color":"#1890ff"},
                                  onClick: ($event: any) => (_ctx.editSnapshot(item))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_tooltip, { title: "发送飞书通知" }, {
                              default: _withCtx(() => [
                                (!_ctx.customSendLark)
                                  ? (_openBlock(), _createBlock(_component_SendOutlined, {
                                      key: 0,
                                      style: {"cursor":"pointer","color":"#1890ff"},
                                      onClick: ($event: any) => (_ctx.sendLark(item.commitId, _ctx.detail?.id, item.branch))
                                    }, null, 8, ["onClick"]))
                                  : (_openBlock(), _createBlock(_component_a_dropdown, {
                                      key: 1,
                                      trigger: ['click']
                                    }, {
                                      overlay: _withCtx(() => [
                                        _createVNode(_component_a_menu, null, {
                                          default: _withCtx(() => [
                                            (item_index < _ctx.showList.length - 1)
                                              ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 0 }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", {
                                                      onClick: _withModifiers(($event: any) => (_ctx.sendMultiLark(item.commitId)), ["prevent"])
                                                    }, [
                                                      _cache[18] || (_cache[18] = _createTextVNode(" 自定义发送通知：当前日志到 ")),
                                                      _createVNode(_component_a_select, {
                                                        value: _ctx.tempEndCommit,
                                                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tempEndCommit) = $event)),
                                                        placeholder: "请选择",
                                                        style: {"width":"100px"},
                                                        "show-search": "",
                                                        filterOption: _ctx.CommitFilter,
                                                        allowClear: "",
                                                        onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"]))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showList.filter((v, index) => index > item_index), (item) => {
                                                            return (_openBlock(), _createBlock(_component_a_select_option, {
                                                              value: item.commitId,
                                                              key: item.commitId,
                                                              title: item.commitId?.slice(0, 7)
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(item.commitId?.slice(0, 7)), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value", "title"]))
                                                          }), 128))
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value", "filterOption"]),
                                                      _cache[19] || (_cache[19] = _createTextVNode(" 的所有日志 "))
                                                    ], 8, _hoisted_24)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_SendOutlined, { style: {"cursor":"pointer","color":"#1890ff"} })
                                      ]),
                                      _: 2
                                    }, 1024))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(item.remark), 1),
                      (item.tagName)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_27, [
                            _createVNode(_component_a_tag, { color: "success" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getBranchName(item.tagName)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("p", _hoisted_28, " 推送到了" + _toDisplayString(_ctx.getBranchName(item.branch)) + "分支（" + _toDisplayString(item.commitId?.slice(0, 7)) + "） ", 1),
                    (item.incId)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_29, " 关联的包版本（build " + _toDisplayString(item.incId) + "） ", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    (!item.tagName && _ctx.showCommit)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.commitMessage)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_31, " 更新日志 "))
                            : _createCommentVNode("", true),
                          (item.commitMessage)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                _createElementVNode("div", _hoisted_33, [
                                  _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.transformCommit(item.commitMessage)), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.showCommit)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (item.tagName)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_35, " 更新日志 "))
                            : _createCommentVNode("", true),
                          (item.tagSummary && item.tagSummary.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                _createElementVNode("div", _hoisted_37, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.tagSummary, (ts, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "description",
                                      key: index
                                    }, _toDisplayString(ts), 1))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    ((_ctx.getVideoFiles(item.fileUrls).length > 0 || _ctx.getImgFiles(item.fileUrls).length > 0) && _ctx.showMedia)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "baseinfo-title" }, " 视频/截图 ", -1)),
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", _hoisted_39, [
                              (_ctx.getVideoFiles(item.fileUrls).length > 0)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getVideoFiles(item.fileUrls), (f, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: index,
                                      style: {"position":"relative"}
                                    }, [
                                      _createElementVNode("video", { src: f }, null, 8, _hoisted_40),
                                      _createElementVNode("div", {
                                        id: "playBtn",
                                        title: "播放视频",
                                        onClick: ($event: any) => (_ctx.onPreview(_ctx.getVideoFiles(item.fileUrls), index)),
                                        class: "circle"
                                      }, _cache[20] || (_cache[20] = [
                                        _createElementVNode("div", { class: "circle_inner_play" }, null, -1)
                                      ]), 8, _hoisted_41)
                                    ]))
                                  }), 128))
                                : _createCommentVNode("", true),
                              (_ctx.getImgFiles(item.fileUrls).length > 0)
                                ? (_openBlock(), _createBlock(_component_a_image_preview_group, { key: 1 }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getImgFiles(item.fileUrls), (f, index) => {
                                        return (_openBlock(), _createBlock(_component_a_image, {
                                          key: index,
                                          src: f,
                                          style: {"position":"relative"},
                                          previewMask: false
                                        }, null, 8, ["src"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.description && _ctx.showDescription)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _cache[22] || (_cache[22] = _createElementVNode("p", { class: "baseinfo-title" }, "备注", -1)),
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, _toDisplayString(item.description), 1)
                            ])
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_45, "暂无更新记录")),
    _createVNode(_component_work_flow_upload, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.visible) = $event)),
      defaultValue: _ctx.current_commit,
      onOnNext: _ctx.onNext,
      versionList: _ctx.versionList
    }, null, 8, ["visible", "defaultValue", "onOnNext", "versionList"]),
    _createVNode(_component_vue_easy_lightbox, {
      index: _ctx.previewIndex,
      visible: _ctx.previewVisible,
      imgs: _ctx.imgs,
      onHide: _cache[10] || (_cache[10] = ($event: any) => (_ctx.previewVisible = false))
    }, null, 8, ["index", "visible", "imgs"])
  ]))
}