import {Router, store} from "@/main";
import * as types from "@/store/constants/user_types";
import { shouldRedirect, redirectPrivate } from "@/utils/redirect";
import {larkLogin} from "@/api/service/login";

export const initDefaultRouter = async () => {
    // 判断是否是需要直接跳转到其他域名的
    if (shouldRedirect()) {
        await redirectPrivate();
    } else {
        await (store as any).restored;
        const is_publish_page = location.pathname && location.pathname.includes("publish");
        const is_in_h5sdk = window.h5sdk;
        // 如果存在sdk，需要先初始化一次
        if (is_in_h5sdk) {
          window.h5sdk.config({
            // 配置项，具体需要根据 SDK 文档来设置，例如 app_id, debug 模式等
            app_id: process.env.VUE_APP_LARK_ID,
          })
        }
        console.log("is_publish_page, is_in_h5sdk", is_publish_page, is_in_h5sdk);
        // const is_in_h5sdk = true;
        if (is_publish_page || is_in_h5sdk) {
            // 获取登录的状态
            const login_online = await store.dispatch(types.CHECK_TOKEN, {
              is_publish_page,
              is_in_h5sdk
            });
            console.warn("login_online", login_online);
            // 如果在飞书内需要登录
            if (!login_online && is_in_h5sdk) {
              // 如果是包下载页面或者包查看页面过来的，直接跳转login
              if (checkFirstPartOfPathname(location.pathname)) {
                setTimeout(() => {
                  Router.push("/login?auto_login=1");
                }, 500);
                return;
              } else {
                try {
                  await larkAutoLogin();
                } catch (e) {
                  //
                }
              }
            }
        }
        // 进主页或者登录
        if (store.getters[types.GETTER_USERNAME] && store.getters[types.GETTER_TOKEN]) {
            Router.addRoute({
                path: "/",
                redirect: "/home/app"
            });
        } else {
            Router.addRoute({
                path: "/",
                redirect: "/login"
            });
        }
    }
}

export const larkAutoLogin = async (redirectedFrom?: string) => {
  try {
    const auto_login_result = await autoLoginLark();
    console.log("auto_login_result", auto_login_result);
    if (auto_login_result) {
      const result = await larkLogin(auto_login_result);
      await store.dispatch(
        types.HANDLE_LOGIN_SUCCESS,
        {
          ...result,
          ...(redirectedFrom ? { redirectedFrom } : {})})
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export const autoLoginLark = () => {
    return new Promise((resolve, reject) => {
          window.h5sdk.ready(() => {
                // 调用JSAPI tt.requestAccess 获取 authorization code
                window.tt.requestAccess({
                  appID: process.env.VUE_APP_LARK_ID,
                  scopeList: [],
                  // 获取成功后的回调
                  success(res: any) {
                    const { code } = res;
                    if (code.length <= 0) {
                      resolve(false);
                    } else {
                      resolve(code);
                    }
                  },
                  // 获取失败后的回调
                  fail(err: any) {
                    console.log(`getAuthCode failed, err:`, JSON.stringify(err));
                    reject(err);
                  }
                })
              }
          )
        })
}

function checkFirstPartOfPathname(pathname: string) {
  const parts = pathname.split("/");
  return parts[1] && (parts[1] === "ios" || parts[1] === "android" || parts[1] === "commit");
}
