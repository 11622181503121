
import { defineComponent } from 'vue'
import { prefixCls } from './constant'

export default defineComponent({
  name: 'ImgTitle',  // 建议添加组件名称
  setup() {
    return {
      prefixCls
    }
  }
})
