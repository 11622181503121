
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import {Form, Input, Modal, Select, Checkbox, Switch} from "ant-design-vue";
import {editAppleAccount} from "@/api/service/workflow";
import Toast from "@/utils/toast";

enum E_repo_type {
  GIT = 'git',
  SVN = 'svn',
  OTHER = 'other'
}

enum E_workflow_type {
  DEV = 'dev',
  DEV_SDK = 'dev_sdk',
  DESIGN = 'design',
  PRODUCT = 'product',
  MODEL = '3d_model',
  TEST = 'test',
  FRONT = 'front',
  SERVER = 'server',
  EFFECTS = 'effects',
  ANIMATION = 'animation',
  ANIMATION_3D = '3d_animation'
}

const M_repo_type_list: any[] = [{
  key: E_repo_type.GIT,
  value: E_repo_type.GIT
}, {
  key: E_repo_type.SVN,
  value: E_repo_type.SVN
}, {
  key: E_repo_type.OTHER,
  value: '其他'
}]

const M_workflow_list: any[] = [{
  key: E_workflow_type.DEV,
  value: '客户端'
}, {
  key: E_workflow_type.DEV_SDK,
  value: '客户端SDK'
}, {
  key: E_workflow_type.FRONT,
  value: '前端'
}, {
  key: E_workflow_type.SERVER,
  value: '后台'
}, {
  key: E_workflow_type.PRODUCT,
  value: '产品'
}, {
  key: E_workflow_type.DESIGN,
  value: '设计'
}, {
  key: E_workflow_type.TEST,
  value: '测试'
}, {
  key: E_workflow_type.MODEL,
  value: '3D模型'
}, {
  key: E_workflow_type.ANIMATION,
  value: '动画'
}, {
  key: E_workflow_type.ANIMATION_3D,
  value: '3D动画'
}, {
  key: E_workflow_type.EFFECTS,
  value: '特效'
}]

@Options({
  components: {
    ASwitch: Switch,
    AModal: Modal,
    AForm: Form,
    AInput: Input,
    ATextarea: Input['TextArea'],
    AFormItem: Form['Item'],
    ASelect: Select,
    ASelectOption: Select['Option'],
    ACheckbox: Checkbox
  }
})

export default class WorkFlowModal extends Vue {
  @Prop()
  private visible?: boolean;
  @Prop()
  private isEdit?: boolean;
  @Prop()
  private appId?: string;
  @Prop()
  private editItem?: any;
  @Prop()
  private workflowUserList?: string[];

  private formState: any = {};
  private defaultFormState = {
    tabName: '',
    manageType: E_repo_type.GIT,
    repoType: E_workflow_type.DEV,
    autoPush: true,
  }
  private labelCol = { span: 6 };
  private wrapperCol = { span: 16 };
  private confirmLoading = false;
  editUserNames: string[] = [];

  get modalTitle() {
    return this.isEdit ? '修改工作流' : '添加工作流';
  }

  get repoList() {
    return M_repo_type_list;
  }

  get workflowList() {
    return M_workflow_list;
  }

  get repoTypeEnum() {
    return E_repo_type;
  }

  @Watch("editItem", {immediate: true})
  private editItemChange(val: any) {
    if (val) {
      this.formState = {
        ...val,
        committers: val.committers ? val.committers : [],
        defaultBranch: val.defaultBranch ? val.defaultBranch.split('/').pop() : null
      };
    } else {
      this.formState = { ...this.defaultFormState };
    }
  }

  userNameFilter(input: string, option: any) {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  // 新增：处理 visible 变化
  private handleVisibleChange(newVisible: boolean) {
    if (!newVisible) {
      // 如果是关闭 modal（取消操作），重置表单
      this.formState = { ...this.defaultFormState };
      this.$emit('modalClose');
    }
    // 通过事件通知父组件，而不是直接修改 prop
    this.$emit('update:visible', newVisible);
  }

  private async handleOk() {
    try {
      this.confirmLoading = true;
      await (this.$refs.form as any).validateFields();
      await editAppleAccount({
        ...this.formState,
        repoPath: this.formState?.repoPath || '',
        defaultBranch: this.formState?.defaultBranch ? `refs/heads/${this.formState?.defaultBranch}` : '',
        appId: this.appId,
        committers: JSON.stringify(this.formState?.committers || [])
      }, this.isEdit);
      await (this.$refs.form as any).resetFields();
      this.$emit('update:visible', false);
      this.$emit("fetchWorkflowList");
      this.$emit('onNext', this.formState?.id, true);
      this.confirmLoading = false;
    } catch (e) {
      this.confirmLoading = false;
      if (e && e.errorFields) {
        e.errorFields.forEach((item: any) => {
          Toast.show("error", item.errors && item.errors[0]);
        })
      } else {
        Toast.show("error", e);
      }
    }
  }
}
