import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeKey) = $event)),
    onChange: _ctx.onChangeTab,
    id: "draggable-tabs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tab_pane, {
        key: "-1",
        tab: "包版本信息",
        closable: false
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowList, (workflow) => {
        return (_openBlock(), _createBlock(_component_a_tab_pane, {
          key: workflow.id
        }, {
          tab: _withCtx(() => [
            _createElementVNode("span", {
              class: "draggable-tab",
              onMousedown: ($event: any) => (_ctx.handleMouseDown($event, workflow)),
              onMouseup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseUp && _ctx.handleMouseUp(...args))),
              onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
            }, _toDisplayString(workflow.tabName), 41, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeKey", "onChange"]))
}