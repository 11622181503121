import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/edit.svg'
import _imports_1 from '@/assets/copy.svg'


const _hoisted_1 = { class: "col-xs-12 col-md-12 col-lg-12" }
const _hoisted_2 = { class: "content-body app-info-content" }
const _hoisted_3 = { class: "row-padding app-info-row over_auto" }
const _hoisted_4 = { class: "main-box" }
const _hoisted_5 = { class: "app-image" }
const _hoisted_6 = { class: "right-detail" }
const _hoisted_7 = { class: "app-details" }
const _hoisted_8 = { class: "app-name-group" }
const _hoisted_9 = { class: "app-name" }
const _hoisted_10 = { class: "app-title" }
const _hoisted_11 = { class: "app-attrs" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "type-text" }
const _hoisted_14 = {
  key: 0,
  class: "app-attrs"
}
const _hoisted_15 = {
  key: 0,
  class: "type-text"
}
const _hoisted_16 = {
  key: 1,
  class: "app-attrs"
}
const _hoisted_17 = { class: "type-text" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "app-attrs tag-box" }
const _hoisted_20 = {
  class: "type-text",
  style: {"margin-right":"0"}
}
const _hoisted_21 = {
  key: 2,
  class: "type-text"
}
const _hoisted_22 = { class: "app-attrs tag-box" }
const _hoisted_23 = {
  class: "type-text",
  style: {"margin-right":"0"}
}
const _hoisted_24 = {
  key: 2,
  class: "type-text"
}
const _hoisted_25 = {
  key: 1,
  class: "mobile-btn-area"
}
const _hoisted_26 = { style: {"display":"flex"} }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "ellipsis pre_wrap" }
const _hoisted_30 = {
  key: 2,
  class: "ellipsis clamp_1"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]
const _hoisted_39 = ["onClick"]
const _hoisted_40 = ["onMousedown"]
const _hoisted_41 = { key: 1 }
const _hoisted_42 = { class: "custom-title" }
const _hoisted_43 = { class: "app-version-info" }
const _hoisted_44 = { class: "app-title" }
const _hoisted_45 = { class: "version-desc" }
const _hoisted_46 = { class: "no-apps-title" }
const _hoisted_47 = { class: "no-apps-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_QrcodeOutlined = _resolveComponent("QrcodeOutlined")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_HeartOutlined = _resolveComponent("HeartOutlined")!
  const _component_BellOutlined = _resolveComponent("BellOutlined")!
  const _component_a_input_group = _resolveComponent("a-input-group")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_uploadOutlined = _resolveComponent("uploadOutlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_InfoCircleOutlined = _resolveComponent("InfoCircleOutlined")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_copy_outlined = _resolveComponent("copy-outlined")!
  const _component_SendOutlined = _resolveComponent("SendOutlined")!
  const _component_highlight_outlined = _resolveComponent("highlight-outlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_work_flow_detail = _resolveComponent("work-flow-detail")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_app_modal = _resolveComponent("app-modal")!
  const _component_work_flow_modal = _resolveComponent("work-flow-modal")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_ctx.app)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("section", {
            class: _normalizeClass(["box", {'m-0': _ctx.isMobile}])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  ref: "can_show_box",
                  class: _normalizeClass(["col-xs-12 col-sm-12 col-md-12 col-lg-12 app-view-btn app-info app-info", {'phone-view': _ctx.isMobile}])
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_image, {
                        preview: false,
                        width: 80,
                        height: 80,
                        src: _ctx.app.appIcon,
                        fallback: _ctx.getDefault(_ctx.app.appType)
                      }, null, 8, ["src", "fallback"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("h3", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.app.appName), 1),
                            _createElementVNode("div", {
                              class: _normalizeClass(["app-tips", {iOS: _ctx.app.appType === 'iOS'}])
                            }, _toDisplayString(_ctx.app.appType), 3),
                            _createVNode(_component_a_tooltip, { title: "App设置" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editApp && _ctx.editApp(...args))),
                                  class: "edit-icon",
                                  src: _imports_0
                                })
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("img", {
                            class: "type-icon",
                            src: _ctx.typeIcon
                          }, null, 8, _hoisted_12),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.app.categoryName), 1)
                        ]),
                        (_ctx.app.bundleId || (_ctx.app.description && !_ctx.isMobile))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                              (_ctx.app.bundleId)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, "Bundle ID: " + _toDisplayString(_ctx.app.bundleId), 1))
                                : _createCommentVNode("", true),
                              (_ctx.app.description && !_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    class: _normalizeClass(["type-text", {more: !!_ctx.app.bundleId}])
                                  }, "应用介绍: " + _toDisplayString(_ctx.app.description), 3))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isMobile && _ctx.app.description)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                              _createElementVNode("span", _hoisted_17, "应用介绍: " + _toDisplayString(_ctx.app.description), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_a_input_group, {
                        compact: "",
                        style: {"display":"flex","flex-shrink":"0","flex-wrap":"wrap"},
                        class: _normalizeClass({'m-b-10': _ctx.isMobile})
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            onClick: _ctx.goHref,
                            readonly: "",
                            value: _ctx.fullPath,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fullPath) = $event)),
                            class: _normalizeClass({'flex_1': _ctx.isMobile}),
                            style: _normalizeStyle({width: _ctx.isMobile ? 'auto' : _ctx.app.appType === 'iOS' ? '300px': '330px'})
                          }, null, 8, ["onClick", "value", "class", "style"]),
                          _createVNode(_component_a_tooltip, { title: "复制链接" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                onClick: _ctx.copy,
                                style: {"padding":"0 9px"}
                              }, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createElementVNode("img", {
                                    src: _imports_1,
                                    style: {"width":"12px","vertical-align":"baseline"}
                                  }, null, -1)
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_button, {
                            class: _normalizeClass(["qrcode-img", {m_r: !_ctx.satisfiedEditPower, 'mobile-qrcode': _ctx.isMobile}]),
                            style: {"margin-left":"8px","padding":"0 9px"}
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_QrcodeOutlined, { style: {"font-size":"12px","color":"#81909A"} })
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_qrcode_vue, {
                                value: _ctx.app.downloadUrl,
                                size: 125,
                                level: "H"
                              }, null, 8, ["value"]),
                              _createVNode(_component_a_image, {
                                preview: false,
                                width: 40,
                                height: 40,
                                src: _ctx.app.appIcon,
                                fallback: _ctx.getDefault(_ctx.app.appType)
                              }, null, 8, ["src", "fallback"])
                            ]),
                            _: 1
                          }, 8, ["class"]),
                          (_ctx.current_app && _ctx.satisfiedEditPower && !_ctx.isMobile)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 0,
                                loading: _ctx.collectLoading,
                                type: _ctx.current_app.collected ? 'primary' : 'default',
                                style: {"margin-left":"8px"},
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleCollect(_ctx.current_app.appId, _ctx.current_app.collected)))
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_HeartOutlined)
                                ]),
                                default: _withCtx(() => [
                                  _createTextVNode(" " + _toDisplayString(_ctx.current_app.collected ? '取消收藏' : '收藏'), 1)
                                ]),
                                _: 1
                              }, 8, ["loading", "type"]))
                            : _createCommentVNode("", true),
                          (_ctx.current_app && _ctx.satisfiedEditPower && !_ctx.isMobile)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 1,
                                loading: _ctx.subscribeLoading,
                                type: _ctx.current_app.subscribed ? 'primary' : 'default',
                                style: {"margin-left":"8px"},
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleSubscribe(_ctx.current_app.appId, _ctx.current_app.subscribed)))
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_BellOutlined)
                                ]),
                                default: _withCtx(() => [
                                  _createTextVNode(" " + _toDisplayString(_ctx.current_app.subscribed ? '取消订阅' : '订阅'), 1)
                                ]),
                                _: 1
                              }, 8, ["loading", "type"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ])
                  ]),
                  (_ctx.satisfiedEditPower)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(_component_a_divider, {
                          orientation: "left",
                          orientationMargin: "0",
                          plain: true,
                          style: {"margin":"6px 0 12px"}
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("已分配的用户权限 ")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("span", _hoisted_20, [
                            _createVNode(_component_a_tooltip, { title: "可上传应用包以及访问此应用下载地址的用户" }, {
                              default: _withCtx(() => [
                                _cache[18] || (_cache[18] = _createTextVNode(" 编辑")),
                                _createVNode(_component_question_circle_outlined, { style: {"font-size":"13px","margin-left":"3px"} })
                              ]),
                              _: 1
                            }),
                            _cache[19] || (_cache[19] = _createTextVNode(" ： "))
                          ]),
                          (_ctx.permit_loading && _ctx.edit_usernames.length > 0)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.edit_usernames, (item) => {
                                return (_openBlock(), _createBlock(_component_a_tag, {
                                  style: {"margin-bottom":"5px"},
                                  color: "blue",
                                  key: item
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            : (!_ctx.permit_loading)
                              ? (_openBlock(), _createBlock(_component_a_spin, {
                                  key: 1,
                                  size: "small"
                                }))
                              : (_openBlock(), _createElementBlock("span", _hoisted_21, "暂无"))
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("span", _hoisted_23, [
                            _createVNode(_component_a_tooltip, { title: "可访问此应用下载地址的用户" }, {
                              default: _withCtx(() => [
                                _cache[20] || (_cache[20] = _createTextVNode(" 查看")),
                                _createVNode(_component_question_circle_outlined, { style: {"font-size":"13px","margin-left":"3px"} })
                              ]),
                              _: 1
                            }),
                            _cache[21] || (_cache[21] = _createTextVNode(" ："))
                          ]),
                          (_ctx.permit_loading && _ctx.download_usernames.length > 0)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.download_usernames, (item) => {
                                return (_openBlock(), _createBlock(_component_a_tag, {
                                  style: {"margin-bottom":"5px"},
                                  color: "blue",
                                  key: item
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            : (!_ctx.permit_loading)
                              ? (_openBlock(), _createBlock(_component_a_spin, {
                                  key: 1,
                                  size: "small"
                                }))
                              : (_openBlock(), _createElementBlock("span", _hoisted_24, "暂无"))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: _ctx.toPublish
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_uploadOutlined)
                          ]),
                          default: _withCtx(() => [
                            _cache[22] || (_cache[22] = _createTextVNode(" 发布/更新应用 "))
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createElementVNode("div", _hoisted_26, [
                          (_ctx.current_app && _ctx.satisfiedEditPower)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 0,
                                loading: _ctx.collectLoading,
                                type: _ctx.current_app.collected ? 'primary' : 'default',
                                style: {"margin-top":"8px"},
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleCollect(_ctx.current_app.appId, _ctx.current_app.collected)))
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_HeartOutlined)
                                ]),
                                _: 1
                              }, 8, ["loading", "type"]))
                            : _createCommentVNode("", true),
                          (_ctx.current_app && _ctx.satisfiedEditPower)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 1,
                                loading: _ctx.subscribeLoading,
                                type: _ctx.current_app.subscribed ? 'primary' : 'default',
                                style: {"margin-top":"8px","margin-left":"8px"},
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleSubscribe(_ctx.current_app.appId, _ctx.current_app.subscribed)))
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_BellOutlined)
                                ]),
                                _: 1
                              }, 8, ["loading", "type"]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2)
              ])
            ])
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["col-lg-12 app-container", {'isInMobile': _ctx.isMobile}])
        }, [
          _createVNode(_component_a_tabs, {
            activeKey: _ctx.activeKey,
            "onUpdate:activeKey": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.activeKey) = $event)),
            onChange: _ctx.onChangeTab,
            id: "draggable-tabs"
          }, {
            rightExtra: _withCtx(() => [
              (_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    onClick: _ctx.addWorkflow
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_plus_outlined)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    onClick: _ctx.addWorkflow
                  }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode("添加工作流")
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_tab_pane, {
                key: "-1",
                tab: "包版本信息",
                closable: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_table, {
                    class: "ant-table-striped version-table",
                    dataSource: _ctx.dataSource,
                    columns: _ctx.columns,
                    pagination: _ctx.showPagination ? _ctx.pagination : false,
                    customRow: _ctx.rowClick,
                    locale: _ctx.locale,
                    scroll: _ctx.isMobile ? { x: 'max-content' } : { },
                    loading: _ctx.table_loading,
                    onChange: _ctx.handleTableChange
                  }, {
                    bodyCell: _withCtx(({ column, record }) => [
                      (column.key === 'version')
                        ? (_openBlock(), _createElementBlock("a", _hoisted_27, [
                            _createTextVNode(_toDisplayString(record.appVersion + " (build " + record.build + ")") + " ", 1),
                            (record.attachFiles &&
                        JSON.parse(record.attachFiles).length > 0)
                              ? (_openBlock(), _createBlock(_component_a_tooltip, {
                                  key: 0,
                                  title: "已上传附件"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_badge, { color: "#108ee9" })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (record.expireDate && _ctx.isExpired(record.expireDate))
                              ? (_openBlock(), _createBlock(_component_a_tooltip, {
                                  key: 1,
                                  title: "证书过期"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_InfoCircleOutlined, { style: {"color":"#faad14","font-size":"16px"} })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (column.key === 'description')
                        ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 1 }, {
                            title: _withCtx(() => [
                              _createElementVNode("span", {
                                class: "inner-html",
                                innerHTML: _ctx.getMdResult(record.description)
                              }, null, 8, _hoisted_28)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_29, _toDisplayString(record.description), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (column.key === 'uploadBy')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(record.uploadBy), 1))
                        : (column.key === 'action')
                          ? (_openBlock(), _createBlock(_component_a_dropdown, {
                              key: 3,
                              trigger: ['click'],
                              class: "handle"
                            }, {
                              overlay: _withCtx(() => [
                                _createVNode(_component_a_menu, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_menu_item, { key: "0" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.editApplication(record.id, record.description, record.attachFiles, record.appVersion, record.build))
                                        }, [
                                          _createVNode(_component_edit_outlined),
                                          _cache[24] || (_cache[24] = _createElementVNode("span", { class: "handle-btn" }, "编辑", -1))
                                        ], 8, _hoisted_31)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_menu_item, { key: "1" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.downloadPackage(record.cdnUrl))
                                        }, [
                                          _createVNode(_component_download_outlined),
                                          _cache[25] || (_cache[25] = _createElementVNode("span", { class: "handle-btn" }, "下载", -1))
                                        ], 8, _hoisted_32)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (record.attachFiles && JSON.parse(record.attachFiles) && JSON.parse(record.attachFiles).length > 0)
                                      ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "2" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              onClick: ($event: any) => (_ctx.downloadAnnex(JSON.parse(record.attachFiles)))
                                            }, [
                                              _createVNode(_component_download_outlined),
                                              _cache[26] || (_cache[26] = _createElementVNode("span", { class: "handle-btn" }, "附件下载", -1))
                                            ], 8, _hoisted_33)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_a_menu_item, { key: "3" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.copyPackageUrl(record.id, record))
                                        }, [
                                          _createVNode(_component_copy_outlined),
                                          _cache[27] || (_cache[27] = _createElementVNode("span", { class: "handle-btn" }, "复制链接", -1))
                                        ], 8, _hoisted_34)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_menu_item, { key: "3" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.pushPackageUrlToLarkUser(record.id, record))
                                        }, [
                                          _createVNode(_component_SendOutlined),
                                          _cache[28] || (_cache[28] = _createElementVNode("span", { class: "handle-btn" }, "推送包信息到飞书", -1))
                                        ], 8, _hoisted_35)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (_ctx.isIOS && record && record.cdnUrl.includes('.ipa'))
                                      ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "6" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              onClick: ($event: any) => (_ctx.reSign(record.id, record.bundleId, record.appVersion, record.build))
                                            }, [
                                              _createVNode(_component_highlight_outlined),
                                              _cache[29] || (_cache[29] = _createElementVNode("span", { class: "handle-btn" }, "重签名", -1))
                                            ], 8, _hoisted_36)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_a_menu_item, { key: "4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.robotDistribute(record.id, true))
                                        }, [
                                          _createVNode(_component_SendOutlined),
                                          _cache[30] || (_cache[30] = _createElementVNode("span", { class: "handle-btn" }, "测试群-机器人群发", -1))
                                        ], 8, _hoisted_37)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (_ctx.satisfiedEditPower)
                                      ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "5" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("a", {
                                              onClick: ($event: any) => (_ctx.robotDistribute(record.id, false))
                                            }, [
                                              _createVNode(_component_SendOutlined),
                                              _cache[31] || (_cache[31] = _createElementVNode("span", { class: "handle-btn" }, "发布群-机器人群发", -1))
                                            ], 8, _hoisted_38)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_a_menu_item, { key: "4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", {
                                          onClick: ($event: any) => (_ctx.sendLarkMessageToViewers(record.id))
                                        }, [
                                          _createVNode(_component_SendOutlined),
                                          _cache[32] || (_cache[32] = _createElementVNode("span", { class: "handle-btn" }, "可查看用户-机器人群发", -1))
                                        ], 8, _hoisted_39)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  style: {"text-decoration":"none"},
                                  onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent","stop"]))
                                }, [
                                  _cache[23] || (_cache[23] = _createTextVNode(" 操作 ")),
                                  _createVNode(_component_DownOutlined)
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["dataSource", "columns", "pagination", "customRow", "locale", "scroll", "loading", "onChange"])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowList, (workflow) => {
                return (_openBlock(), _createBlock(_component_a_tab_pane, {
                  key: workflow.id
                }, {
                  tab: _withCtx(() => [
                    (_ctx.satisfiedEditPower)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "draggable-tab",
                          onMousedown: ($event: any) => (_ctx.handleMouseDown($event, workflow)),
                          onMouseup: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleMouseUp && _ctx.handleMouseUp(...args))),
                          onMouseleave: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
                        }, _toDisplayString(workflow.tabName), 41, _hoisted_40))
                      : (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(workflow.tabName), 1))
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_work_flow_detail, {
                          list: _ctx.currentCommitLog,
                          detail: workflow,
                          loading: _ctx.workflowLoading,
                          onEditWorkflow: _ctx.editWorkflow,
                          onOnNext: _ctx.onChangeTab,
                          versionList: _ctx.appVersionOptions,
                          onDeleteWorkflow: _ctx.deleteWorkflow
                        }, null, 8, ["list", "detail", "loading", "onEditWorkflow", "onOnNext", "versionList", "onDeleteWorkflow"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey", "onChange"])
        ], 2),
        _createVNode(_component_a_modal, {
          visible: _ctx.packageModalVisible,
          "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.packageModalVisible) = $event)),
          onOk: _ctx.packageModalOk,
          onClose: _ctx.packageModalReset,
          okText: "保存",
          cancelText: "取消",
          confirmLoading: _ctx.confirmLoading
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_42, [
              _cache[34] || (_cache[34] = _createElementVNode("span", null, "编辑版本", -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.currentVersion), 1),
              _cache[35] || (_cache[35] = _createElementVNode("span", { class: "title-placeholder" }, null, -1))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              "label-col": _ctx.labelCol,
              "wrapper-col": _ctx.wrapperCol,
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                (_ctx.annexList.length > 0)
                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                      key: 0,
                      label: "附件列表"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload, {
                          "file-list": _ctx.annexList,
                          "onUpdate:fileList": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.annexList) = $event)),
                          name: "file",
                          multiple: true,
                          "before-upload": _ctx.beforeAnnexUpload,
                          disabled: true
                        }, null, 8, ["file-list", "before-upload"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_form_item, { label: "备注" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _ctx.c_description,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.c_description) = $event)),
                      placeholder: "请输入备注",
                      autosize: true
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label-col", "wrapper-col"])
          ]),
          _: 1
        }, 8, ["visible", "onOk", "onClose", "confirmLoading"]),
        _createVNode(_component_a_modal, {
          visible: _ctx.signModalVisible,
          "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.signModalVisible) = $event)),
          onOk: _ctx.signModalOk,
          onCancel: _ctx.signModalReset,
          okText: "确认",
          cancelText: "取消",
          confirmLoading: _ctx.confirmLoading
        }, {
          title: _withCtx(() => _cache[36] || (_cache[36] = [
            _createElementVNode("div", { class: "custom-title" }, [
              _createElementVNode("span", null, "重签名")
            ], -1)
          ])),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_43, [
              _createVNode(_component_a_image, {
                preview: false,
                width: 80,
                height: 80,
                src: _ctx.app.appIcon,
                fallback: _ctx.getDefault(_ctx.app.appType)
              }, null, 8, ["src", "fallback"]),
              _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.app.appName), 1),
              _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.currentVersion), 1)
            ]),
            _createVNode(_component_a_form, {
              "label-col": _ctx.labelCol,
              "wrapper-col": _ctx.wrapperCol,
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, { label: "签名证书" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _ctx.certId,
                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.certId) = $event)),
                      placeholder: "请选择签名证书",
                      "show-search": "",
                      filterOption: _ctx.groupFilter,
                      onChange: _ctx.changeCert
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certList, (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            value: item.id,
                            key: item.id,
                            title: item.groupId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.groupId), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "title"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "filterOption", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label-col", "wrapper-col"])
          ]),
          _: 1
        }, 8, ["visible", "onOk", "onCancel", "confirmLoading"]),
        _createVNode(_component_app_modal, {
          visible: _ctx.visible,
          modalTitle: _ctx.modalTitle,
          formState: _ctx.formState,
          edit_user_ids: _ctx.edit_user_ids,
          download_user_ids: _ctx.download_user_ids,
          onReset: _ctx.reset,
          onFetchDetail: _ctx.fetchDetail
        }, null, 8, ["visible", "modalTitle", "formState", "edit_user_ids", "download_user_ids", "onReset", "onFetchDetail"]),
        _createVNode(_component_work_flow_modal, {
          visible: _ctx.editWorkflowModalVisible,
          "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editWorkflowModalVisible) = $event)),
          "is-edit": _ctx.workflowIsEdit,
          appId: _ctx.currentAppId,
          "edit-item": _ctx.editWorkflowItem,
          workflowUserList: _ctx.workflowUserList,
          onModalClose: _ctx.workFlowModalClose,
          onFetchWorkflowList: _ctx.getWorkflowList,
          onOnNext: _ctx.onChangeTab
        }, null, 8, ["visible", "is-edit", "appId", "edit-item", "workflowUserList", "onModalClose", "onFetchWorkflowList", "onOnNext"])
      ], 64))
    : (_openBlock(), _createBlock(_component_a_empty, {
        key: 1,
        style: {"margin":"auto 8px"},
        image: _ctx.noAppImg,
        "image-style": {
          width: '98px',
          height: '86px',
          display: 'inline-block'
        }
      }, {
        description: _withCtx(() => [
          _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.noAppTitle), 1),
          _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.noAppContent), 1)
        ]),
        _: 1
      }, 8, ["image"]))
}