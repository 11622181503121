
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import {Action, Getter} from "vuex-class";
import {
  GETTER_CATEGORY_LIST,
  GETTER_CURRENT_APP_ID,
  GETTER_LEVEL,
  GETTER_PRIVATE_URL,
  GETTER_TOKEN,
  GETTER_USER_LIST,
  UPDATE_APP_LIST,
  UPDATE_CATEGORY_LIST
} from "@/store/constants/user_types";
import {editApp} from "@/api/service/app";
import useClipboard from "vue-clipboard3";
import config from "@/config";
import {LoadingOutlined, PlusOutlined, SendOutlined} from "@ant-design/icons-vue";
import {getRanNumChat, isMobileDevice} from "@/utils/utils";
import {GETTER_FILTER} from "@/store/constants/filter_types";
import Toast from "@/utils/toast";
import {Col, Form, Input, Modal, Radio, Row, Select, Tooltip, Upload, Button} from "ant-design-vue"
import {E_Lark_type, sendLarkNotification} from "@/utils/redirect/lark_notification";
import {sendLarkMessage} from "@/api/service/notification";
import {areArraysEqual} from "@/utils/common";

@Options({
  methods: {sendLarkMessage},
  components: {
    LoadingOutlined,
    PlusOutlined,
    SendOutlined,
    AModal: Modal,
    AForm: Form,
    AFormItem: Form['Item'],
    AUpload: Upload,
    ARow: Row,
    ACol: Col,
    ATextarea: Input['TextArea'],
    ARadio: Radio,
    ARadioGroup: Radio['Group'],
    ASelect: Select,
    AInput: Input,
    ASelectOption: Select['Option'],
    AInputPassword: Input['Password'],
    ATooltip: Tooltip,
    AButton: Button,
  }
})

export default class AppModal extends Vue {
  @Prop()
  private download_user_ids?: string[];
  @Prop()
  private visible?: boolean;
  @Prop()
  private edit_user_ids?: string[];
  @Prop()
  private modalTitle?: string;
  @Prop()
  private formState?: any;
  @Getter(GETTER_LEVEL)
  private roleLevel: any;
  @Getter(GETTER_USER_LIST)
  private userList: any;
  @Getter(GETTER_PRIVATE_URL)
  private privateUrl: any;
  @Getter(GETTER_TOKEN)
  private token: any;
  @Action(UPDATE_APP_LIST)
  private updateAppList: any;
  @Getter(GETTER_CURRENT_APP_ID)
  private currentAppId: any;
  @Getter(GETTER_CATEGORY_LIST)
  private categoryList: any;
  @Getter(GETTER_FILTER)
  private getterFilters: any;
  @Action(UPDATE_CATEGORY_LIST)
  private updateCategoryList: any;

  private labelCol: any = {span: 24};
  private wrapperCol: any = {span: 24};
  private btnLoading = false;
  private modalVisible = false;
  private confirmLoading = false;
  private downloadUserIds: string[] = [];
  private editUserIds: string[] = [];

  private categoryFilter(input: string, option: any) {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  @Watch("visible")
  private toggleState() {
    this.modalVisible = !!this.visible;
    // 显示的时候过滤掉可能的管理员，避免显示异常
    const downloadUserIds = this.download_user_ids ? this.download_user_ids : [];
    this.downloadUserIds = downloadUserIds.filter(
        (v: string) => !this.adminList.find(
            (admin: any) => admin.userId === v
        )
    );
    const editUserIds = this.edit_user_ids ? this.edit_user_ids : [];
    this.editUserIds = editUserIds.filter(
        (v: string) => !this.adminList.find(
            (admin: any) => admin.userId === v
        )
    )
  }

  get supplyData() {
    return {
      type: "app"
    }
  }

  // 判断是否满足编辑权限
  get satisfiedEditPower() {
    return this.roleLevel === 100;
  }

  get iconUploadUrl() {
    return config.BASE_URL + "api/upload"
  }

  get chooseUserList() {
    return this.userList.filter((v: any) => v.roleLevel !== 100);
  }

  get adminList() {
    return this.userList.filter((v: any) => v.roleLevel === 100);
  }

  get getModalWidth() {
    // return document.body.clientWidth * 0.6 > 800 ? 800 : 600;
    return 800;
  }

  get uploadHeader() {
    return {
      token: this.token
    }
  }

  get defaultPath() {
    return this.formState.appType === 'iOS' ? '/ios/' : '/android/';
  }

  get addon() {
    return window.location.origin + this.defaultPath;
  }

  get privateAddon() {
    return this.privateUrl + this.defaultPath
  }

  get editableUserList() {
    return this.chooseUserList.filter(
        (v: any) =>
            !this.downloadUserIds ||
            !this.downloadUserIds.includes(v.userId)
    );
  }

  get viewableUserList() {
    return this.chooseUserList.filter(
        (v: any) =>
            !this.editUserIds ||
            !this.editUserIds.includes(v.userId))
  }

  get isMobile() {
    return isMobileDevice();
  }

  get colSpan() {
    return this.isMobile ? 24 : 11;
  }

  private validator(password: string) {
    const reg_sp = new RegExp(/^(\d)\1{5}$/);
    const str = "0123456789_9876543210";
    return !(reg_sp.test(password) || str.indexOf(password) > -1);

  }

  private validatePassword = async (rule: any, value: string) => {
    const pattern = /^[0-9]{6,10}$/;
    if (value === "") {
      return Promise.reject("请输入密码");
    } else if (!pattern.test(value)) {
      return Promise.reject("密码必须是6-10位的数字");
    } else if (!this.validator(this.formState.password)) {
      return Promise.reject("密码强度不够")
    } else {
      return Promise.resolve();
    }
  }

  private async copyPrivateUrl() {
    try {
      const {toClipboard} = useClipboard()
      await toClipboard(this.privateAddon + this.formState.publicPath);
      Toast.show("success", "已复制到剪切板")
    } catch (e) {
      Toast.show("warning", e);
    }
  }

  private async copyPassword() {
    try {
      const {toClipboard} = useClipboard()
      await toClipboard(this.formState.password);
      Toast.show("success", "已复制到剪切板")
    } catch (e) {
      Toast.show("warning", e);
    }
  }

  private async copyPublicUrl() {
    try {
      const {toClipboard} = useClipboard()
      await toClipboard(this.addon + this.formState.path);
      Toast.show("success", "已复制到剪切板")
    } catch (e) {
      Toast.show("warning", e);
    }
  }

  private reset() {
    this.$emit("reset");
  }

  private beforeUpload(file: any) {
    console.log("file", file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Toast.show("warning", "必须上传图片类型的文件");
      return false;
    }
    return true;
  }

  private handleChange(info: any) {
    console.log("file", info);
    // const success = info.file && info.file.response && info.file.response.code === 200;
    // if (success) {
    //   this.imageUrl = info.file.response.data.url;
    // }
    if (info.file.status === 'uploading') {
      this.btnLoading = true;
      return;
    }
    if (info.file.status === 'done') {
      this.formState.appIcon = info.file.response.data.url;
    }
    if (info.file.status === 'error') {
      this.btnLoading = false;
      Toast.show("error", 'upload error')
    }
  }

  private async handleOk() {
    try {
      this.confirmLoading = true;
      await (this.$refs.form as any).validateFields();
      const edit_users: any = this.editUserIds && this.editUserIds.map((v: string) => {
        return {
          userId: v,
          permit: "edit"
        }
      })
      const download_users: any = this.downloadUserIds && this.downloadUserIds.map((v: string) => {
        return {
          userId: v,
          permit: "view"
        }
      })
      // 保存的时候，比较查看权限的人是否有变化，有的话，给他们发通知
      const difference = this.downloadUserIds.filter(item => !this.download_user_ids?.includes(item));
      if (difference.length > 0) {
        sendLarkNotification({
          appId: this.currentAppId,
          receiveType: E_Lark_type.user_ids,
          userIds: difference,
        })
      }
      const form: any = {
        ...this.formState,
        downloadUrl: this.addon + this.formState.path,
        distributeUsers: [...edit_users, ...download_users]
      }
      if (this.formState.publicPath && this.privateUrl) {
        form.supportUrl = this.privateAddon + this.formState.publicPath;
      }
      await editApp(form);
      Toast.show("success", "保存成功")
      this.reset();
      this.updateAppList(this.getterFilters);
      this.updateCategoryList();
      // 如果当前已有current-app-id，无法触发watch，需要手动刷新
      if (this.currentAppId) {
        // this.fetchAppDetail();
        this.$emit("fetchDetail");
      }
      this.confirmLoading = false;
    } catch (info) {
      this.confirmLoading = false;
      if (info && info.errorFields) {
        info.errorFields.forEach((item: any) => {
          Toast.show("error", item.errors && item.errors[0]);
        })
      } else {
        // 如果返回链接重复，自动重置
        if (info === "下载链接重复，请重新设置") {
          this.formState.path = getRanNumChat(4);
          Toast.show("warning", "下载链接重复，已自动重新生成");
        } else {
          Toast.show("error", info);
        }
      }
    }
  }

  private sendLarkMessageToViewers() {
    if (areArraysEqual(this.downloadUserIds, this.download_user_ids)) {
      sendLarkNotification({
        appId: this.currentAppId,
        receiveType: E_Lark_type.view_app,
      }, false)
    } else {
      Toast.show("error", "请先点击保存按钮将您的设置更新再点击推送");
    }
  }
}
