
import { defineComponent } from 'vue'
import { prefixCls } from './constant'

export default defineComponent({
  setup() {
    return {
      prefixCls
    }
  }
})
