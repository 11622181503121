import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/copy.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_outlined = _resolveComponent("loading-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.modalTitle,
    visible: _ctx.modalVisible,
    "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.modalVisible) = $event)),
    onOk: _ctx.handleOk,
    onCancel: _ctx.reset,
    okText: "确认",
    cancelText: "取消",
    width: _ctx.getModalWidth,
    centered: "",
    confirmLoading: _ctx.confirmLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.formState,
        ref: "form",
        name: "basic",
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol,
        autocomplete: "off"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { class: "icon-upload" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_upload, {
                name: "file",
                "list-type": "picture-card",
                class: "avatar-uploader",
                "show-upload-list": false,
                "before-upload": _ctx.beforeUpload,
                onChange: _ctx.handleChange,
                headers: _ctx.uploadHeader,
                action: _ctx.iconUploadUrl,
                data: _ctx.supplyData
              }, {
                default: _withCtx(() => [
                  (_ctx.formState.appIcon)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.formState.appIcon,
                        alt: "avatar"
                      }, null, 8, _hoisted_1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_ctx.btnLoading)
                          ? (_openBlock(), _createBlock(_component_loading_outlined, { key: 0 }))
                          : (_openBlock(), _createBlock(_component_plus_outlined, { key: 1 })),
                        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "ant-upload-text" }, "添加图标", -1))
                      ]))
                ]),
                _: 1
              }, 8, ["before-upload", "onChange", "headers", "action", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: _ctx.colSpan }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "名称",
                    name: "appName",
                    rules: [{ required: true, message: '请输入应用名称!' }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formState.appName,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.appName) = $event)),
                        placeholder: "请输入应用名称",
                        readonly: !_ctx.satisfiedEditPower
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["span"]),
              (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_a_col, {
                    key: 0,
                    span: 2
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_col, { span: _ctx.colSpan }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "系统",
                    name: "appType",
                    rules: [{ required: true, message: '请选择系统!' }]
                  }, {
                    default: _withCtx(() => [
                      (_ctx.satisfiedEditPower)
                        ? (_openBlock(), _createBlock(_component_a_radio_group, {
                            key: 0,
                            value: _ctx.formState.appType,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.appType) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio, { value: "iOS" }, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode("iOS")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_a_radio, { value: "Android" }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("Android")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : (_openBlock(), _createBlock(_component_a_input, {
                            key: 1,
                            value: _ctx.formState.appType,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.appType) = $event)),
                            readonly: ""
                          }, null, 8, ["value"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["span"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: _ctx.colSpan }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "应用分类",
                    name: "categoryId"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.satisfiedEditPower)
                        ? (_openBlock(), _createBlock(_component_a_select, {
                            key: 0,
                            value: _ctx.formState.categoryId,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.categoryId) = $event)),
                            placeholder: "请选择应用分类",
                            "show-search": "",
                            filterOption: _ctx.categoryFilter
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryList, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  value: item.id,
                                  key: item.id,
                                  title: item.categoryName
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.categoryName), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "title"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "filterOption"]))
                        : (_openBlock(), _createBlock(_component_a_input, {
                            key: 1,
                            value: _ctx.formState.categoryName,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.categoryName) = $event)),
                            readonly: ""
                          }, null, 8, ["value"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_a_col, { span: 2 }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createElementVNode("input", {
                    type: "password",
                    autocomplete: "new-password",
                    style: {"display":"none"}
                  }, null, -1)
                ])),
                _: 1
              }),
              _createVNode(_component_a_col, { span: _ctx.colSpan }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "安装密码",
                    name: "password",
                    rules: [{ required: true, validator: _ctx.validatePassword }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_password, {
                        value: _ctx.formState.password,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.password) = $event)),
                        placeholder: "请输入密码",
                        readonly: !_ctx.satisfiedEditPower
                      }, {
                        addonAfter: _withCtx(() => [
                          _createVNode(_component_a_tooltip, { title: "复制密码" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.copyPassword && _ctx.copyPassword(...args))),
                                src: _imports_0,
                                style: {"width":"12px","vertical-align":"baseline","cursor":"pointer"}
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }, 8, ["rules"])
                ]),
                _: 1
              }, 8, ["span"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: _ctx.colSpan }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "下载地址",
                    name: "path",
                    rules: [{ required: true, message: '请补全下载地址!' }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formState.path,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.path) = $event)),
                        "addon-before": _ctx.addon,
                        readonly: !_ctx.satisfiedEditPower
                      }, {
                        addonAfter: _withCtx(() => [
                          _createVNode(_component_a_tooltip, { title: "复制链接" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.copyPublicUrl && _ctx.copyPublicUrl(...args))),
                                src: _imports_0,
                                style: {"width":"12px","vertical-align":"baseline","cursor":"pointer"}
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "addon-before", "readonly"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["span"]),
              (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_a_col, {
                    key: 0,
                    span: 2
                  }))
                : _createCommentVNode("", true),
              (this.privateUrl)
                ? (_openBlock(), _createBlock(_component_a_col, {
                    key: 1,
                    span: _ctx.colSpan
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { label: "辅助链接" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formState.publicPath,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.publicPath) = $event)),
                            "addon-before": _ctx.privateAddon
                          }, {
                            addonAfter: _withCtx(() => [
                              _createVNode(_component_a_tooltip, { title: "复制链接" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.copyPrivateUrl && _ctx.copyPrivateUrl(...args))),
                                    src: _imports_0,
                                    style: {"width":"12px","vertical-align":"baseline","cursor":"pointer"}
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "addon-before"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["span"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "应用介绍" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                placeholder: "请输入应用介绍",
                value: _ctx.formState.description,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.description) = $event)),
                readonly: !_ctx.satisfiedEditPower,
                autosize: { minRows: 2, maxRows: 10 }
              }, null, 8, ["value", "readonly"])
            ]),
            _: 1
          }),
          (_ctx.satisfiedEditPower)
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 0,
                label: "编辑权限"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.editUserIds,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editUserIds) = $event)),
                    mode: "multiple",
                    style: {"width":"100%"},
                    placeholder: "请选择用户",
                    filterOption: _ctx.categoryFilter
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editableUserList, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.userId,
                          value: item.userId,
                          title: item.username
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.username), 1)
                          ]),
                          _: 2
                        }, 1032, ["value", "title"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "filterOption"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.satisfiedEditPower)
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 1,
                label: "访问权限"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.downloadUserIds,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.downloadUserIds) = $event)),
                    mode: "multiple",
                    style: {"width":"100%"},
                    placeholder: "请选择用户",
                    filterOption: _ctx.categoryFilter
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewableUserList, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.userId,
                          value: item.userId,
                          title: item.username
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.username), 1)
                          ]),
                          _: 2
                        }, 1032, ["value", "title"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "filterOption"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_a_form_item, {
            label: "机器人Webhook地址-测试群",
            name: "devWeComUrl"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.devWeComUrl,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formState.devWeComUrl) = $event)),
                placeholder: "请输入机器人Webhook地址-测试群"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          (_ctx.satisfiedEditPower)
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 2,
                label: "机器人Webhook地址-发布群",
                name: "prodWeComUrl"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.prodWeComUrl,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formState.prodWeComUrl) = $event)),
                    placeholder: "请输入机器人Webhook地址-发布群"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["model", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["title", "visible", "onOk", "onCancel", "width", "confirmLoading"]))
}