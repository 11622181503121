
import { defineComponent } from 'vue'
import SvgIcon from './svg-icon.vue'
import { prefixCls } from './constant'

export default defineComponent({
  name: 'ImgOnError',
  components: {
    SvgIcon
  },
  setup() {
    return {
      prefixCls
    }
  }
})
