export const GETTER_USERNAME = "GETTER_USERNAME";
export const LOGIN = "LOGIN";
export const HANDLE_LOGIN_SUCCESS = "HANDLE_LOGIN_SUCCESS";
export const UPDATE_USERNAME = "UPDATE_USERNAME";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_TOKEN_RECORD_TIMESTAMP = "UPDATE_TOKEN_RECORD_TIMESTAMP";
export const UPDATE_LEVEL = "UPDATE_LEVEL";
export const UPDATE_CURRENT_USER_ID = "UPDATE_CURRENT_USER_ID";
export const UPDATE_PRIVATE_URL = "UPDATE_PRIVATE_URL";
export const GETTER_CURRENT_USER_ID = "GETTER_CURRENT_USER_ID";
export const GETTER_TOKEN = "GETTER_TOKEN";
export const GETTER_TOKEN_RECORD_TIMESTAMP = "GETTER_TOKEN_RECORD_TIMESTAMP";
export const GETTER_LEVEL = "GETTER_LEVEL";
export const GETTER_PRIVATE_URL = "GETTER_PRIVATE_URL";
export const UPDATE_APP_LIST = "UPDATE_APP_LIST";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export const UPDATE_CATEGORY_LIST = "UPDATE_CATEGORY_LIST";
export const UPDATE_CURRENT_APP_ID = "UPDATE_CURRENT_APP_ID";
export const GETTER_CURRENT_APP_ID = "GETTER_CURRENT_APP_ID";
export const GETTER_CATEGORY_LIST = "GETTER_CATEGORY_LIST";
export const GETTER_ACTUAL_CATEGORY_LIST = "GETTER_ACTUAL_CATEGORY_LIST";
export const GETTER_CURRENT_APP = "GETTER_CURRENT_APP";
export const GETTER_APP_LIST = "GETTER_APP_LIST";
export const GETTER_USER_LIST = "GETTER_USER_LIST";
export const RESET_STORE = "RESET_STORE";
export const GETTER_VERSION_TOKEN = "GETTER_VERSION_TOKEN";
export const UPDATE_VERSION_TOKEN = "UPDATE_VERSION_TOKEN";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const UPDATE_USER_CACHE = "UPDATE_USER_CACHE";
export const GETTER_USER_CACHE = "GETTER_USER_CACHE";

export const getDefaultUserState = () => {
    return {
        username: "",
        token: "",
        token_record_timestamp: "",
        roleLevel: 0,
        userId: "",
        appList: [],
        userList: [],
        categoryList: [],
        currentChooseAppId: "",
        privateUrl: "",
        versionToken: "",
        cacheUserHistory: {}
    }
}
