import { BaseApi } from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";

export const get_notification_list = async (params) => {
  try {
    const result = await BaseApi.get(PATH_URL.NOTIFICATIONS, {
      params
    })
    console.log("get_notification_list result", result);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const read_notification = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.NOTIFICATION, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const sendWecomMessage = async (
  appId,
  appVersionId,
  key
) => {
  try {
    await BaseApi.post(PATH_URL.SEND_WECOM_MESSAGE, {
      appId,
      appVersionId,
      robotUrl: key
    })
  } catch (e) {
    return Promise.reject(e);
  }
}


export const sendLarkMessage = async (params) => {
  try {
    await BaseApi.post(PATH_URL.SEND_LARK_MESSAGE, {
      ...params
    })
  } catch (e) {
    return Promise.reject(e);
  }
}

export const sendMultiLarkMessage = async (appId, workflowId, commitIds) => {
  try {
    await BaseApi.post(PATH_URL.SEND_MULTI_LARK_MESSAGE, {
      appId,
      workflowId,
      commitIds
    })
  } catch (e) {
    return Promise.reject(e);
  }
}
